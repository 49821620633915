import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOfferItem, IOffersData } from "../../../configs/offers/offers.types";
import { CookieService } from "../../../utils/cookieService";
import {
	cachingViewedOffer,
	getInitialSortedOffersData,
	getSeparatedOffersData,
	getShackedOffersData,
	getSortedOffers,
	sortOffers,
} from "./helpers";
import { getOffers } from "../../../api/methods/getConfigs";
import { LocalStorageService } from "../../../utils/localStorageService";
import { isEqualData } from "../../../utils/isEqualData";
import { RootState } from "../../rootReducer";

export interface IInitialState {
	data: IOffersData | null;
	isLoading: boolean;
	error: null | string;
	isUploaded: boolean;
	needUpdate: boolean;
}

export const initialState: IInitialState = {
	data: null,
	isLoading: false,
	error: null,
	isUploaded: false,
	needUpdate: true,
}

export const getOffersData = createAsyncThunk(
	'offersSlice/getOffers',
	async ({ isFromStorage }: { isFromStorage: boolean, withoutLoader?: boolean }, thunkApi) => {
		try {
			const storageData = LocalStorageService.get<IOffersData, null>('offersData', null);
			
			let newData: IOffersData | null = null;

			const cashedViewedIds = CookieService.get('viewedOfferIds');

			let isUploaded = false;
			let needUpdate = true;

			if (isFromStorage) {
				newData = !storageData
					? await getOffers()
					: storageData;
				
				!storageData && (isUploaded = true);
			} else {
				const state = thunkApi.getState() as RootState;
				const currentData = (state?.offers?.data ?? {}) as IOffersData;
				const currentOffersData = (currentData.data ?? []) as IOfferItem[]
				const currentDataIds = currentOffersData.map(getSortedOffers)
					.sort(sortOffers);
		
				const respData = await getOffers();
				const respDataIds = respData.data.map(getSortedOffers)
					.sort(sortOffers);

				if (isEqualData(currentDataIds, respDataIds)) {
					needUpdate = false;
					newData = currentData;
				} else {
					newData = respData;
				}

				isUploaded = true;
			}

			const viewedOfferIds = cashedViewedIds
				? cashedViewedIds.split(',')
				: [];

			const data = {
				data: getInitialSortedOffersData(newData.data, viewedOfferIds),
				tags: newData.tags,
				settings: newData.settings,
				isUploaded,
				needUpdate,
			}

			LocalStorageService.set('offersData', data);
			
			return { data, isUploaded, needUpdate };
		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
);

export const reviewSlice = createSlice({
	name: 'reviews',
	initialState,
	reducers: {
		setIsViewed(state, action: PayloadAction<{id: number, cookieExpiresDays?: number}>) {
			if (state.data) {
				const dataCopy = state.data.data.map(d => ({...d}));

				const { id, cookieExpiresDays } = action.payload;

				let viewed: IOfferItem[] = [];
				let notViewed: IOfferItem[] = [];

				dataCopy.forEach((offer: IOfferItem) => {
					if (offer.id === id && !offer.isViewed) {
						viewed.push({ ...offer, isViewed: true, isBestOffer: false });
					} else {
						notViewed.push({...offer});
					}
				});

				const updatedData = [...notViewed, ...viewed];
				const separatedData = getSeparatedOffersData(updatedData);
				const result = getShackedOffersData(separatedData);

				state.data.data = result;
				
				cachingViewedOffer(result, cookieExpiresDays);
			}
		},
		setIsUploaded(state, action: PayloadAction<boolean>) {
			state.isUploaded = action.payload;
		},
		setIsLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getOffersData.pending, (state, action) => {
			if (!action?.meta?.arg?.withoutLoader) {
				state.isLoading = true;
			}
		});

		builder.addCase(getOffersData.fulfilled, (state, action) => {
			const {
				data, isUploaded, needUpdate,
			} = action.payload;

			state.isUploaded = isUploaded;

			if (needUpdate) {
				state.data = data;
				state.isLoading = false;
				state.error = null;
			}
		});

		builder.addCase(getOffersData.rejected, (state, action) => {
			state.isLoading  = false;
			state.error = action.payload as string;
			state.isUploaded = false;
		});
	}
});

export const { setIsViewed, setIsUploaded } = reviewSlice.actions;

export default reviewSlice.reducer;
