import React from 'react';
import { useAppSelector } from '../../store';
import HeroAnswer1 from '../../assets/images/svg/hero/hero_thinking.svg';
import HeroAnswer2 from '../../assets/images/svg/hero/hero_timer.svg';
import HeroAnswer3 from '../../assets/images/svg/hero/hero_calendar.svg';
import './answer.sass';

const IMAGES = [HeroAnswer1, HeroAnswer2, HeroAnswer3];

export function Answer() {
  const dataConfig = useAppSelector(state => state.configs.general.data?.main);
  
  return (
    <div className='answer'>
      {(dataConfig?.answerBlock ?? []).map((item, idx)=> (
        <div className="answer__item" key={item.id}>
          <div
            className="answer__img"
            style={{backgroundImage: `url('${item.icon ?? IMAGES[idx]}')`}}
          />
            <div className="answer__text">
              <h3 className="answer__title">{item.title}</h3>
              <p className="answer__description">{item.description}</p>
            </div>
        </div>
      ))}

    </div>
  );
}
