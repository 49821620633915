const data = {
  "title": "Отзывтеріміздің клиенттерінің",
  "items": [
    {
      "id": 1,
      "rating": 5,
      "author": "Толкын",
      "date": "",
      "description": ["Finero - тез және қол жетімді микро кредит іздейтіндер үшін ыңғайлы шешім. Сіздің қызметіңізден көп қуаныштым."]
    },
    {
      "id": 2,
      "rating": 5,
      "author": "Виктор",
      "date": "",
      "description": ["Бұл қызмет менің қиын жағдайда құтқарылу болды. Несие алу процесі тез және оңай болды, шарттары өте ашық болды. Өте ұсынылады."]
    },
    {
      "id": 3,
      "rating": 4,
      "author": "Болат",
      "date": "",
      "description": ["Мен Finero-ны шұғыл шағын несие алу үшін пайдаландым. Төмен пайыздық мөлшерлемелер мен қызмет көрсету сапасы мені таң қалдырды. Қиын уақытта қолдау көрсеткеніңіз үшін рахмет."]
    },
  ]
}

export default data;
