import pujckabezvolani from './img/1_pujckabezvolani.svg';
import ofin from './img/2_ofin.svg';
import tando from './img/3_tando_logo.svg';
import zaplo from './img/4_zaplo.webp';
import pujckaplus from './img/5_pujckaplus.webp';
import viasms from './img/6_viasms.svg';
import ferratum from './img/7_ferratum.webp';
import provident from './img/8_provident.webp';
import svycarskapujcka from './img/9_svycarskapujcka.svg';
import creditportal from './img/10_creditportal.webp';
import coolcredit from './img/11_coolcredit.svg';
import logo from './img/logo.webp';

const data = {
	data: [
		{
			id: 1,
			title: "PůjčkabezVolani",
			money: "1 000 - 15 000 Kč",
			img: pujckabezvolani,
			term: "30 dní",
			link: "https://track.sjednatpujcku.cz/click/8",
			puZa: "Ano",
			minVek: "18",
			tagId: [1],
			isBestOffer: true,
			campaignId: 1
		},
		{
			id: 2,
			title: "OFIN",
			money: "1 000 - 30 000 Kč",
			img: ofin,
			term: "5 – 30 dní",
			link: "https://track.sjednatpujcku.cz/click/18",
			puZa: "Ne",
			minVek: "18",
			tagId: [16],
			isBestOffer: true,
			campaignId: 2
		},
		{
			id: 3,
			title: "TANDO",
			money: "1 000 - 30 000 Kč",
			img: tando,
			term: "6 - 36 měsíců",
			link: "https://track.sjednatpujcku.cz/click/20",
			puZa: "Ne",
			minVek: "18",
			tagId: [2],
			isBestOffer: true,
			campaignId: 3
		},
		{
			id: 4,
			title: "PŮJČKAPLUS",
			money: "1 000 - 30 000 Kč",
			img: pujckaplus,
			term: "30 dní",
			link: "https://track.sjednatpujcku.cz/click/5",
			puZa: "Ano",
			minVek: "18",
			tagId: [1],
			isBestOffer: true,
			campaignId: 4
		},
		{
			id: 5,
			title: "Viasms",
			money: "1 000 - 60 000 Kč",
			img: viasms,
			term: "30 dní – 120 měsíců",
			link: "https://track.sjednatpujcku.cz/click/2",
			puZa: "Ano",
			minVek: "18",
			tagId: [1],
			campaignId: 5
		},
		{
			id: 6,
			title: "Ferratum",
			money: "2 500 - 80 000 Kč",
			img: ferratum,
			term: "30 dní – 120 měsíců",
			link: "https://track.sjednatpujcku.cz/click/3",
			puZa: "Ne",
			minVek: "18",
			tagId: [5],
			campaignId: 6
		},
		{
			id: 7,
			title: "Provident",
			money: "3 000 - 130 000 Kč",
			img: provident,
			term: "30 dní – 36 měsíců",
			link: "https://track.sjednatpujcku.cz/click/9",
			puZa: "Ano",
			minVek: "18",
			tagId: [5],
			campaignId: 7
		},
		{
			id: 8,
			title: "Švýcarská Půjčka",
			money: "1 000 - 20 000 Kč",
			img: svycarskapujcka,
			term: "15 – 30 dní",
			link: "https://track.sjednatpujcku.cz/click/10",
			puZa: "Ne",
			minVek: "18",
			tagId: [7],
			campaignId: 8
		},
		{
			id: 13,
			title: "Creditportal",
			money: "500 - 60 000 Kč",
			img: creditportal,
			term: "30 dní – 12 měsíců",
			link: "https://track.sjednatpujcku.cz/click/17",
			puZa: "Ne",
			minVek: "18",
			tagId: [5],
			campaignId: 9
		},
		{
			id: 16,
			title: "Cool Credit",
			money: "1 000 - 25 000 Kč",
			img: coolcredit,
			term: "5 - 30 dní",
			link: "https://track.sjednatpujcku.cz/click/6",
			puZa: "Ne",
			minVek: "18",
			tagId: [7],
			campaignId: 10
		},
		{
			id: 17,
			title: "Zaplo",
			money: "1 000 - 30 000 Kč",
			img: zaplo,
			term: "7 - 30 dní",
			link: "https://track.sjednatpujcku.cz/click/19",
			puZa: "Ano",
			minVek: "18",
			tagId: [1],
			campaignId: 11
		}
	],
	"tags": [
		{
			"id": 1,
			"name": "půjčka zdarma"
		},
		{
			"id": 2,
			"name": "úplně fresh půjčka"
		},
		{
			"id": 3,
			"name": "pvní půjčka až 15.000 Kč na 10 dní je zdarma!"
		},
		{
			"id": 4,
			"name": "první půjčka zdarma. Peníze na účtu do 15 minut"
		},
		{
			"id": 5,
			"name": "flexibilní půjčka"
		},
		{
			"id": 6,
			"name": "jen jedna splátka navíc"
		},
		{
			"id": 7,
			"name": "expres půjčka"
		},
		{
			"id": 8,
			"name": "bez ověření úvěrové historie"
		},
		{
			"id": 9,
			"name": "půjčka s pojištěním schopnosti splácet"
		},
		{
			"id": 10,
			"name": "peníze do 15 minut od schválení. Na cokoliv"
		},
		{
			"id": 11,
			"name": "prvních 30 dní máte 0% úrok"
		},
		{
			"id": 12,
			"name": "hotovost na benzinkách EuroOil"
		},
		{
			"id": 13,
			"name": "pro financování Vašeho podnikání"
		},
		{
			"id": 14,
			"name": "vracíme až 103 000 Kč"
		},
		{
			"id": 15,
			"name": "individuální přístup. Prodloužení půjčky o 7, 14 nebo 30 dní"
		},
		{
			"id": 16,
			"name": "nová půjčka"
		}
	],
	settings: {
		rangeMoney: {
			title: "Částka",
			currency: "Kč"
		},
		rangeTerm: {
			title: "Splatnost",
			currency: [
				{
					id: 1,
					name: "dní"
				},
				{
					id: 30,
					name: "měsíců"
				},
				{
					id: 365,
					name: "rok"
				}
			],
			min: 1,
			step: 1,
			initialValue: 14
		},
		minVek: "Min. věk",
		puZa: "Půjčka zdarma",
		linkText: "Získat peníze",
		"cookieExpiresDays": 2,
		"title": "TYTO SPOLEČNOSTI TI PŮJČÍ",
		"subtitle": "Maximální šance na schválení půjčky při žádosti u více společností.",
		"logo": logo
	}	
}

export default data;