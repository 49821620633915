import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { LocalStorageService } from "../../../utils/localStorageService";
import { IFormsConfigData } from "../../../configs/forms/forms.types";
import { LS_FORMS_CONFIG_KEY } from "../../../configs/storageKeys";
import { getSendFormAction } from './actions';
import { getFormsConfigData } from "../../../api/methods/getConfigs";

export type IInitialState = {
	loading: boolean;
	error: string;
	errors: { [key: string]: string };
	data: any;
	successStep: number;
	configForms: {
		data: null | IFormsConfigData;
		error: null | string;
		isLoading: boolean;
	}
}

export const initialState: IInitialState = {
	loading: false,
	error: '',
	errors: {},
	// data: LocalStorageService.get<IObject<any>, IObject<any>>('form-data', {}),
	data: {},
	successStep: 0,
	configForms: {
		data: null,
		error: null,
		isLoading: false,
	}
}

export const getFormsConfig = createAsyncThunk(
	'formData/getFormsConfig',
	async (_, thunkApi) => {
		try {
			const cachedData = LocalStorageService
			.get<IFormsConfigData, null>(LS_FORMS_CONFIG_KEY, null);

			if (cachedData) {
				return { data: cachedData };
			}

			const data = await getFormsConfigData();

			LocalStorageService.set<IFormsConfigData>(LS_FORMS_CONFIG_KEY, data);

			return { data };
		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
);

export const sendFormData = createAsyncThunk(
	'formData/sendFormData',
	getSendFormAction(),
)

export const formSlice = createSlice({
	name: 'formData',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(sendFormData.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(sendFormData.fulfilled, (state, action) => {
			const { data, errors } = action.payload;

			state.loading = false;
			state.data = data;
			state.errors = errors as any;
		});

		builder.addCase(sendFormData.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as string;
		});

		builder.addCase(getFormsConfig.pending, (state, action) => {
			state.configForms.isLoading = true;
		});

		builder.addCase(getFormsConfig.fulfilled, (state, action) => {
			const { data } = action.payload;

			state.configForms.isLoading = false;
			state.configForms.data = data;
			state.configForms.error = null;
		});

		builder.addCase(getFormsConfig.rejected, (state, action) => {
			state.configForms.isLoading = false;
			state.configForms.error = action.payload as string;
		});
	}
});

// export const {} = formSlice.actions;

export default formSlice.reducer;
