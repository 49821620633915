import { findTag } from "../../utils/cardList";
import { ICardListComponent } from "./CardList.types";
import { useAppSelector } from "../../store";
import { sendUserAction } from "../../api/methods/sendUserAction/sendUserAction";
// import { useSendDelayOnOfferAction } from "../../api/methods/sendUserAction/hooks";
import logo from '../../assets/images/gallery/bank.png';
import './CardList.sass';
import { getBGImage, getCardBG } from "./helpers";

export function CardList({
	data,
	tags,
	settings,
	category = 'all',
  onClickOffer,
}: ICardListComponent) {
  const clickID = useAppSelector(state => state.userData.clickID);

  // const {
  //   onAction: onSendBlockDelay,
  //   onStopTimer: onStopBlockDelayTimer,
  // } = useSendDelayOnOfferAction(clickID);

  // const {
  //   onAction: onSendButtonDelay,
  //   onStopTimer: onStopButtonDelayTimer,
  // } = useSendDelayOnOfferAction(clickID);
  
  // const onSendOfferAction = (
  //   name: string,
  //   delay: number,
  //   onAction: (name: string, delay: number) => void,
  //   onStopTimer: () => void,
  // ) => {
  //   return (event: any) => {
  //     if (event.target === event.currentTarget) {
  //       onAction(name, delay);
  //       onStopTimer();
  //     }
  //   }
  // };

  

  const goToOffer = () => {
    sendUserAction<number>(clickID, [{ name: 'offer_click', value: 1 }]);
  }

  const onClickOfferCard = (e: any, id: number, link: string, campaignId: number) => {
    e.stopPropagation();
    onClickOfferHandle(e, id, campaignId);

    window.open(link, '_blank');
  }

  const onClickOfferHandle = (e: any, id: number, campaignId: number) => {
    e.stopPropagation();
    onClickOffer(id);
    sendUserAction<number>(clickID, [{ name: 'offer_click', value: 1 }, { name: 'pb_cmpgn_id', value: campaignId }]);
  }

  return (
    <ul
			className={`card-list ${category} ${data.length >= 4 ? 'grid' : ''} ${data.length < 4 ? 'flex' : ''}`}
		>
      {data.map((item) => (
        <li
          key={item.id}
          className={`item ${item.isViewed ? 'viewed' : ''}`}
          onClick={(e) => onClickOfferCard(e, item.id, item.link, item.campaignId)}
        >
          <div
            className="card"
            style={{
              ...getCardBG(item.background),
              padding: item.isBanner ? 0 : '15px',
            }}
            // onMouseEnter={
            //   onSendOfferAction(`delayed_offer_${item.title}`, 3000, onSendBlockDelay, onStopButtonDelayTimer)
            // }
            // onMouseLeave={onStopBlockDelayTimer}
          >
            {item.isBanner ? (
              <div style={{
                ...getBGImage(item.img),
                width: '100%',
                height: '100%',
              }} />
            ) : (
              <>
                <div className="img__container">
                  <img
                    className="img"
                    src={item.img || logo}
                    alt={item.title}
                    height="40"
                    width="120"
                    loading="lazy"
                  />
                </div>
                
                <div className="tags">
                  {item.tagId.map((tag) => (
                    <button className="tag" key={tag}>#{findTag(tag, tags)}</button>
                  ))}
                </div>

                <div className="prop">
                  <span className="hidomd">{settings.rangeMoney.title}</span>
                  <span><strong>{item.money}</strong></span>
                </div>
                {!!settings.rangeTerm && (
                  <div className="hidomd">
                    <div className="prop">
                      <span>{settings.rangeTerm.title}</span>
                      <span><strong>{item.term}</strong></span>
                    </div>
                  </div>
                )}
                
                <a
                  href={item.link}
                  className="link"
                  target={'_blank'}
                  rel="noreferrer"
                  // onMouseOver={
                  //   onSendOfferAction(`delayed_btn_offer_${item.title}`, 5000, onSendButtonDelay, onStopBlockDelayTimer)
                  // }
                  // onMouseLeave={onStopButtonDelayTimer}
                  onClick={(e) => onClickOfferHandle(e, item.id, item.campaignId)}
                >
                  {settings.linkText}
                </a>
              </>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
