const data = {
	cz: {
		scriptName: '81484708-796f-445d-9696-ebbbcad4c6ab.js',
		gaScriptUrl: 'track.sjednatpujcku.cz',
		gaID: 'GTM-KZZ2P4G',
	},
	kz: {
		scriptName: '682ce1d7-cb8e-421e-8f4d-898d8f2ab5c7.js',
		gaScriptUrl: 'track.finero.kz',
		gaID: 'GTM-PLR442HB',
	},
	kz_kz: {
		scriptName: '682ce1d7-cb8e-421e-8f4d-898d8f2ab5c7.js',
		gaScriptUrl: 'track.finero.kz',
		gaID: 'GTM-PLR442HB',
	},
	mx: {
		scriptName: '75a21d79-ef33-451e-a2d7-46d787bc51e8.js',
		gaScriptUrl: 'track.finero.mx',
		gaID: 'GTM-MV3S9P8L',
	},
	local: {
		scriptName: '81484708-796f-445d-9696-ebbbcad4c6ab.js',
		gaScriptUrl: 'track.sjednatpujcku.cz',
		gaID: 'GTM-KZZ2P4G',
	}
}

export const getGoogleScripts = (tld?: 'cz' | 'kz' | 'kz_kz' | 'mx') => {
	const currentTLD = tld ?? 'cz';

	return data[currentTLD];
}
