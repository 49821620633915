import { combineReducers } from "@reduxjs/toolkit";
import mainReducer from "./features/mainData/mainDataSlice";
import formReducer from "./features/formSlice/formSlice";
import configsReducer from "./features/configsSlice/configsSlice";
import reviewsReducer from "./features/reviewSlice/reviewSlice";
import offersReducer from "./features/offersSlice/offersSlice";
import userReducer from "./features/userSlice/userSlice";

const reducers = {
	formData: formReducer,
	mainData: mainReducer,
	configs: configsReducer,
	reviews: reviewsReducer,
	offers: offersReducer,
	userData: userReducer,
};

export const combinedReducers = combineReducers(reducers);

export type RootState = ReturnType<typeof combinedReducers>;
