import creditPlus from './img/1_creditPlus.svg';
import moneyman from './img/3_moneyman.svg';
import solva_kz from './img/4_solva_kz.svg';
import onecredit from './img/5_onecredit.svg';
import tengebai_kz from './img/6_tengebai_kz.svg';
import vivus from './img/7_vivus.svg';
import ccloan from './img/8_ccloan.svg';
import acredit from './img/9_acredit.svg';
import zaimer_kz from './img/10_zaimer_kz.svg';
import dengiclick from './img/11_dengiclick.svg';
import creditbar from './img/12_creditbar.svg';
import credit365 from './img/13_credit365.svg';
import turbomoney_kz from './img/14_turbomoney_kz.png';
import qanat from './img/15_qanat.svg';
import tengos from './img/17_tengos.svg';
import selfcredit from './img/18_selficredit.png';
import gmoney from './img/20_gomoney.svg';
import logo from './img/logo.png';

const data = {
	"data": [
		{
			"id": 1,
			"title": "CreditPlus",
			"money": "300 000 ₸ дейін",
			"img": creditPlus,
			"term": "",
			"link": "https://track.finero.kz/click/4",
			"tagId": [4],
			"isBestOffer": true
		},
		{
			"id": 2,
			"title": "Tengos",
			"money": "500 000 ₸ дейін",
			"img": tengos,
			"term": "",
			"link": "https://track.finero.kz/click/18",
			"tagId": [27],
			"isBestOffer": true
		},
		{
			"id": 3,
			"title": "MoneyMan",
			"money": "300 000 ₸ дейін",
			"img": moneyman,
			"term": "",
			"link": "https://track.finero.kz/click/7",
			"tagId": [6],
			"isBestOffer": true
		},
		{
			"id": 4,
			"title": "Solva",
			"money": "300 000 ₸ дейін",
			"img": solva_kz,
			"term": "",
			"link": "https://track.finero.kz/click/19",
			"tagId": [18],
			"isBestOffer": true
		},
		{
			"id": 5,
			"title": "Zaimer",
			"money": "300 000 ₸ дейін",
			"img": zaimer_kz,
			"term": "",
			"link": "https://track.finero.kz/click/20",
			"tagId": [19]
		},
		{
			"id": 6,
			"title": "Qanat",
			"money": "143 000 ₸ дейін",
			"img": qanat,
			"term": "",
			"link": "https://track.finero.kz/click/10",
			"tagId": [9]
		},
		{
			"id": 7,
			"title": "Tengebai",
			"money": "300 000 ₸ дейін",
			"img": tengebai_kz,
			"term": "",
			"link": "https://track.finero.kz/click/17",
			"tagId": [13]
		},
		{
			"id": 8,
			"title": "Credit365",
			"money": "300 000 ₸ дейін",
			"img": credit365,
			"term": "",
			"link": "https://track.finero.kz/click/3",
			"tagId": [3]
		},
		{
			"id": 9,
			"title": "GMoney",
			"money": "180 000 ₸ дейін",
			"img": gmoney,
			"term": "",
			"link": "https://track.finero.kz/click/11",
			"tagId": [10]
		},
		{
			"id": 10,
			"title": "Turbomoney",
			"money": "143 000 ₸ дейін",
			"img": turbomoney_kz,
			"term": "",
			"link": "https://track.finero.kz/click/8",
			"tagId": [7]
		},
		{
			"id": 11,
			"title": "Ccloan",
			"money": "165 000 ₸ дейін",
			"img": ccloan,
			"term": "",
			"link": "https://track.finero.kz/click/12",
			"tagId": [11]
		},
		{
			"id": 12,
			"title": "Dengiclick",
			"money": "300 000 ₸ дейін",
			"img": dengiclick,
			"term": "",
			"link": "https://track.finero.kz/click/5",
			"tagId": [25]
		},
		{
			"id": 13,
			"title": "Vivus",
			"money": "172 500 ₸ дейін",
			"img": vivus,
			"term": "",
			"link": "https://track.finero.kz/click/9",
			"tagId": [8]
		},
		{
			"id": 14,
			"title": "SelfieCredit",
			"money": "165 000 ₸ дейін",
			"img": selfcredit,
			"term": "",
			"link": "https://track.finero.kz/click/18",
			"tagId": [28]
		},
		{
			"id": 15,
			"title": "Onecredit",
			"money": "300 000 ₸ дейін",
			"img": onecredit,
			"term": "",
			"link": "https://track.finero.kz/click/2",
			"tagId": [2]
		},
		{
			"id": 16,
			"title": "Acredit",
			"money": "300 000 ₸ дейін",
			"img": acredit,
			"term": "",
			"link": "https://track.finero.kz/click/1",
			"tagId": [1]
		},
		{
			"id": 17,
			"title": "Creditbar",
			"money": "300 000 ₸ дейін",
			"img": creditbar,
			"term": "",
			"link": "https://track.finero.kz/click/6",
			"tagId": [20]
		}
	],
	"tags": [
		{
			"id": 1,
			"name": "Apple жабдықтарының сыйлығы"
		},
		{
			"id": 2,
			"name": "Саяхат ұтып алыңыз"
		},
		{
			"id": 3,
			"name": "Мальдив аралдарындағы демалыс"
		},
		{
			"id": 4,
			"name": "15 минутта онлайн"
		},
		{
			"id": 5,
			"name": "Одобрение за 10 секунд"
		},
		{
			"id": 6,
			"name": "1 миллион ұтыс"
		},
		{
			"id": 7,
			"name": "15 минутта картаға"
		},
		{
			"id": 8,
			"name": "100% онлайн өтінім"
		},
		{
			"id": 9,
			"name": "7 минуттан бастап өтінішті қарау."
		},
		{
			"id": 10,
			"name": "Розгрыш путевки и iPhone"
		},
		{
			"id": 11,
			"name": "бастап 0,99%"
		},
		{
			"id": 12,
			"name": "Под залог авто"
		},
		{
			"id": 13,
			"name": "Кез келген несие тарихымен"
		},
		{
			"id": 14,
			"name": "Работаем круглосуточно 24/7"
		},
		{
			"id": 15,
			"name": "3 способа получения денег"
		},
		{
			"id": 16,
			"name": "Промокоды и акции"
		},
		{
			"id": 17,
			"name": "200 000 ₸ дейін қайталанатын өтінім"
		},
		{
			"id": 18,
			"name": "Бірден сандық картада"
		},
		{
			"id": 19,
			"name": "2 минут ішінде шешім"
		},
		{
			"id": 20,
			"name": "Бір-екі-үш сияқты қарапайым"
		},
		{
			"id": 21,
			"name": "Всегда 0% первые 7 дней"
		},
		{
			"id": 22,
			"name": "Быстрый микрокредит на карту"
		},
		{
			"id": 23,
			"name": "Кредит без залога онлайн"
		},
		{
			"id": 24,
			"name": "Моментальная выдача"
		},
		{
			"id": 25,
			"name": "Tөмен пайыздар %"
		},
		{
			"id": 26,
			"name": "Картаға кез келген мақсатқа"
		},
		{
			"id": 27,
			"name": "Тегін несие"
		},
		{
			"id": 28,
			"name": "Сауалнама орнына Selfie"
		},
		{
			"id": 29,
			"name": "100 000 ₸ бастап микрокредиттер"
		}
	],
	"settings": {
		"rangeMoney": {
			"title": "Сома",
			"currency": "₸"
		},
		"linkText": "Ақшаны алыңыз",
		"cookieExpiresDays": 2,
		"title": "Онлайн-микрокредиттер",
		"subtitle": "Несколько компанияларға хабарласқанда микрокредитті қабылдау шансының ең жоғарғы деңгейі.",
		logo: logo
	}	
}

export default data;
