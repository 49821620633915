import React from 'react';
import {Link} from "react-router-dom";
import {PrimaryButton} from "../../UI/PrimaryButton";
import {FORM_LINK} from "../../constants/constantApi";
import logo from '../../assets/images/svg/logo.svg'
import './about.sass';
import { useAppSelector } from '../../store';
import { useInView } from 'react-intersection-observer';

export function About() {
  const dataConfig = useAppSelector(state => state.configs.general.data?.about);
  const commonConfig = useAppSelector(state => state.configs.general.data?.common);

  const { ref } = useInView({
    threshold: .2,
  });

  return (
    <section
      className='section about'
      ref={ref}
    >
      <div className="container about__container">
          <div className="about__text">
            <h2 className='section__title'>{dataConfig?.title}</h2>
            {(dataConfig?.description ?? []).map((item, i) =>(
              <p className="section__description" key={i}>{item}</p>
            ))}
          </div>

          <table className="about__table">
            <thead>
              <tr className='about__row'>
                <th className='about__thead'>{dataConfig?.tableData.head.question}</th>
                <th className='about__thead'>
                  <img src={dataConfig?.tableData.head.logo ?? logo} alt="" loading="lazy" />
                </th>
                <th className='about__thead'>{dataConfig?.tableData.head.comment}</th>
              </tr>
            </thead>
            <tbody>
            {(dataConfig?.tableData.body ?? []).map(item => (
              <tr className='about__row' key={item.id}>
                <td className='about__tbody'>{item.question}</td>
                <td className='about__tbody'>{item.answer}</td>
                <td className='about__tbody'>{item.comment}</td>
              </tr>
            ))}
            </tbody>
          </table>
        <Link to={FORM_LINK}>
          <PrimaryButton text={commonConfig?.primaryButtonText ?? ''}/>
        </Link>
      </div>
      </section>
  );
}
