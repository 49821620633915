import React from 'react';
import {Link} from "react-router-dom";
import { useAppSelector } from '../../store';
import {PrimaryButton} from "../../UI/PrimaryButton";
import {FORM_LINK} from "../../constants/constantApi";
import './banner.sass';
import { useInView } from 'react-intersection-observer';

export function Banner() {
  const commonConfig = useAppSelector(state => state.configs.general.data);
  const bannerConfig = useAppSelector(state => state.configs.general.data?.banner);

  const { ref } = useInView({
    threshold: .2,
  });

  return (
    <section
      className='section banner container'
      ref={ref}
    >
        <div className="container banner__container negative">
          <img className="banner__img" src={bannerConfig?.img} alt="" loading="lazy" />
          <div className="banner__text">
              <h2 className='section__title'>{bannerConfig?.title}</h2>
              {(bannerConfig?.description ?? []).map((item, index) => (
                  <p className="section__description" key={index}>{item}</p>
              ))}
            <Link to={FORM_LINK}>
              <PrimaryButton text={commonConfig?.common.primaryButtonText ?? ''}/>
            </Link>
          </div>
        </div>
    </section>
  );
}
