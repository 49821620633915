import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import {lazy, Suspense, useEffect, useState} from "react";
import { getForm, getRedirectUrl } from './helpers';
import { REDIRECT_URL, TLD } from "../../constants/constantApi";
import { updateStep } from "../../store/features/mainData/mainDataSlice";
import './customform.sass';
import './range.sass';
import { getStepsLength } from "../../utils/configHelpers";
import { getDomain } from "../../utils/getTld";

const CustomForm = lazy(() => import('./CustomForm'));

export function CustomFormContainer() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    errors: errorsState,
    error: commonError,
  } = useAppSelector(state => state.formData);

  const formsConfig = useAppSelector(state => state.formData.configForms);

  const {
    step,
  } = useAppSelector(state => state.mainData);
  
  const [reverseInputs, setReverseInputs] = useState<[boolean, string]>([false, '']) // [different_address, src_of_income]

  const stepsLength = getStepsLength(formsConfig?.data?.stepsLength);

  const isRedirect = step > stepsLength;
  const redirectUrl = getRedirectUrl();

  const isRedirectForCZ = getDomain(window.location.href) === TLD.kz;

  const isIframe = location?.state?.isIframe;

  useEffect(() => {
    if (isRedirect) {
      // navigate('/' + PENDING, { state: { isIframe } });
      navigate('/' + redirectUrl, { state: { isIframe } });

      dispatch(updateStep(stepsLength));
    };
  }, [step, stepsLength, redirectUrl]);

  useEffect(() => {
    if (commonError && isRedirectForCZ) {
      window.location.href = REDIRECT_URL;
    }
  }, [commonError]);

  const handleInputs = (arr:[boolean, string]) => {
    setReverseInputs(arr)
  }

  if (isRedirect || isRedirectForCZ) {
    return null;
  }

  const form = getForm(step, reverseInputs, errorsState, formsConfig.data);

  // console.log('step', step)
  // console.log('isRedirect', isRedirect)
  // console.log('isRedirectForCZ', isRedirectForCZ)
  // console.log('form', form)
  
  return (
    <Suspense fallback={<div>...Loading</div>}>
      <CustomForm form={form} handleInputs={handleInputs} isIframe={isIframe} />
    </Suspense>
  )
}

