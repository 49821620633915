import { general } from './general';
import { reviews } from './review';
import { forms } from './forms';
import { offers } from './offers';

export const configs = {
	general,
	reviews,
	forms,
	offers
};
