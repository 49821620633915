import React, {useEffect, useState} from 'react';
import {useInView} from "react-intersection-observer";
import classNames from "classnames";

import {NAV_LINK_3} from "../../constants/constantApi";
import {ISetActiveLink} from "../../hooks/useObserver";
import './faq.sass';
import { useAppSelector } from '../../store';
// import { useBlockOverSend } from '../../api/methods/sendUserAction/hooks';
// import { BLOCK_IDs } from '../../api/methods/sendUserAction/constants';
// import { sendUserAction } from '../../api/methods/sendUserAction/sendUserAction';

export function Faq({setActive}: ISetActiveLink) {
  const config = useAppSelector(state => state.configs.general.data?.faq);
  // const clickID = useAppSelector(state => state.userData.clickID);

  const [value, setValue] = useState<number | null>(null);

  const { ref, inView } = useInView({
    threshold: .2,
  });

  // const onSendActionHandler = useBlockOverSend(inView, BLOCK_IDs.faq);

  function handleClick(id: number) {
    if (id !== value) {
      // sendUserAction<boolean>(clickID, [{ name: `faq_item_${id}`, values: true }]);
      setValue(id);
    } else {
      setValue(null);
    }
  };

  useEffect(() => {
    if (inView) setActive(NAV_LINK_3)
  }, [inView]);

  return (
    <section
      className="section faq container"
      id={NAV_LINK_3}
      ref={ref}
      // onMouseOver={onSendActionHandler}
    >
      <h2 className="section__title">{config?.title}</h2>
      <div className="faq__accordion-container">
        <div className="faq__accordion">
          {(config?.data ?? []).filter((el, i) => i % 2 === 0)
            .map((item) => (
              <div className={classNames('faq__card', item.id === value ? 'active' : '')} key={item.id}  >
                <button className="faq__subtitle" onClick={() => handleClick(item.id)}>
                  {item.title}
                  <div className="faq__button" >
                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_46_1165)">
                        <path d="M20 11.4286H11.4286V20H8.57143V11.4286H0V8.57143H8.57143V0H11.4286V8.57143H20V11.4286Z"/>
                      </g>
                    </svg>
                  </div>
                </button>
                <p className="faq__description">{item.description}</p>
              </div>
          ))}
        </div>
        <div className="faq__accordion">
          {(config?.data ?? []).filter((el, i) => i % 2 === 1)
            .map((item) => (
              <div className={classNames('faq__card', item.id === value ? 'active' : '')} key={item.id}  >
                <button className="faq__subtitle" onClick={() => handleClick(item.id)}>
                  {item.title}
                  <div className="faq__button" >
                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_46_1165)">
                        <path d="M20 11.4286H11.4286V20H8.57143V11.4286H0V8.57143H8.57143V0H11.4286V8.57143H20V11.4286Z"/>
                      </g>
                    </svg>
                  </div>
                </button>
                <p className="faq__description">{item.description}</p>
              </div>
          ))}
        </div>
      </div>

    </section>
  );
}
