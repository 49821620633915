import { IOfferItem } from "../../../configs/offers/offers.types";
import { CookieService } from "../../../utils/cookieService";
import { isEqualData } from "../../../utils/isEqualData";

export const cachingViewedOffer = (data: IOfferItem[], cookieExpiresDays = 2) => {
	const cachedIdsStr = CookieService.get('viewedOfferIds') || '';
	const cachedIds = (cachedIdsStr ? cachedIdsStr.split(',') : [])
		.map(id => Number(id))
		.sort((a, b) => a - b)
	
	const viewedIds = data
		.filter(item => item.isViewed)
		.map(item => item.id)
		.sort((a, b) => a - b);

	if (viewedIds.length && !isEqualData(viewedIds, cachedIds)) {
		CookieService.set<number[]>('viewedOfferIds', viewedIds, cookieExpiresDays);
	}
}

export const setViewedOffer = (offersData: IOfferItem[], viewedList: string[]) => {
	const best: IOfferItem[] = [];
	const cards: IOfferItem[] = [];
	
	offersData.forEach(offer => {
		if (viewedList.includes(String(offer.id))) {
			cards.push({ ...offer, isViewed: true, isBestOffer: false });
		} else {
			best.push({ ...offer });
		}
	})

	return [...best, ...cards];
}

export const getSeparatedOffersData = (data: IOfferItem[]) => {
	const { best, cards } = data.reduce((res: { best: IOfferItem[], cards: IOfferItem[] }, item) => {
		if (item.isBestOffer) res.best.push({...item});
		else res.cards.push({...item});

		return res;
	}, {
		best: [],
		cards: [],
	});

	return { best, cards };
}

export const getShackedOffersData = ({ best, cards }: { best: IOfferItem[], cards: IOfferItem[] }) => {
	if (best.length < 4) {
		const count = 4 - best.length;

		const { removed, main } = cards.reduce((acc: {removed: IOfferItem[], main: IOfferItem[]}, item, idx) => {
			if (idx < count) {
				acc.removed.push({...item});
			} else {
				acc.main.push({...item});
			}

			return acc;
		}, {
			removed: [], main: [],
		});

		const currentBest: IOfferItem[] = [...best, ...removed]
			.map(offer => ({ ...offer, isBestOffer: true, isViewed: false }));

		const currentCards: IOfferItem[] = main;

		

		return [...currentBest, ...currentCards];
	} else {
		return [...best, ...sortByIsViewed(cards)];
	}
}

export const getInitialSortedOffersData = (data: IOfferItem[], viewedList: string[]) => {
	const offersData = setViewedOffer(data, viewedList);
	const separatedData = getSeparatedOffersData(offersData);

	const result = getShackedOffersData(separatedData);

	return result;
}

function sortByIsViewed(data: IOfferItem[]) {
	const arr = data.map(d => ({...d}));

	return arr.sort((a: IOfferItem, b: IOfferItem) => {
		if (!a.isViewed && b.isViewed) {
				return -1;
		} else if (a.isViewed === true && !b.isViewed) {
				return 1;
		} else {
				return 0;
		}
	});
}

export interface ISortedOffers {
	id: number;
	title: string;
	img: string;
}

export const getSortedOffers = ({ id, title, img }: IOfferItem) => ({ id, title, img });

export const sortOffers = (a: ISortedOffers, b: ISortedOffers) => a.id - b.id; 