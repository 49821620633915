import { useEffect } from 'react';

export const LoadAnalyticsScripts = ({ gaID }: { gaID: string }) => {
  useEffect(() => {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gaID}"
              height="0" width="0" style="display: none; visibility: hidden;"></iframe>
    `;
    
    document.body.insertBefore(noscript, document.body.firstChild);

		return () => {
			document.body.removeChild(noscript);
		};
  }, [gaID]);

  return null;
};
