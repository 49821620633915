export class CookieService {
	// Устанавливает cookie с указанным именем, значением и сроком хранения
	static set<T>(name: string, value: T | T[], days: number = 2): void {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // срок хранения 2 дня
			const expires = `expires=${date.toUTCString()}`;
			document.cookie = `${name}=${value};${expires};path=/`;
	}

	// Получает значение cookie по его имени
	static get(name: string): string | null {
			const nameEQ = `${name}=`;
			const cookies = document.cookie.split(';');
			for (let cookie of cookies) {
					cookie = cookie.trim();
					if (cookie.indexOf(nameEQ) === 0) {
							return cookie.substring(nameEQ.length, cookie.length);
					}
			}
			return null;
	}

	// Удаляет cookie по его имени
	static remove(name: string): void {
			document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
	}
}