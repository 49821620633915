export const OffersItemBG = {
  image: 'image',
  bg: 'bg',
} as const;

export type OffersItemBGType = keyof typeof OffersItemBG;

export interface IOffersItemBG {
  type: OffersItemBGType,
  value: string,
}

export interface IOfferItem {
  id: number;
  title: string;
  money: string;
  img: string;
  term: string;
  link: string;
  puZa?: string;
  minVek?: string;
  tagId: number[];
	isBestOffer?: boolean;
  isViewed?: boolean;
  background?: IOffersItemBG,
  isBanner?: boolean,
  campaignId: number;
}

export interface IOffersTag {
	id: number;
	name: string;
}

interface IOffersRangeTerm {
  title: string;
  currency: IOffersTag[];
  min: number;
  step: number;
  initialValue: number;
}

export interface IOffersRangeMoney {
	title: string;
	currency: string;
}

export interface IOffersSettings {
	rangeMoney: IOffersRangeMoney;
	rangeTerm?: IOffersRangeTerm;
  minVek?: string;
  puZa?: string;
  linkText: string;
  cookieExpiresDays?: number;
  title: string;
  subtitle: string;
  logo?: string;
}

export interface IOffersData {
	data: IOfferItem[];
	tags: IOffersTag[];
	settings: IOffersSettings;
}