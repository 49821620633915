const data = {
	"title": "Recenze od našich klientů",
	"items": [
		{
			"id": 1,
			"rating": 5,
			"author": "Maria L.",
			"date": "",
			"description": ["Byl jsem v těžké finanční situaci a potřeboval jsem rychle peníze. Žádost o mikroúvěr byla velmi snadná a rychlá. Peníze byly na mém účtu do 24 hodin. Velmi doporučuji!"]
		},
		{
			"id": 2,
			"rating": 5,
			"author": "Petr V.",
			"date": "",
			"description": ["Potřeboval jsem peníze na opravu auta a mikroúvěr mi pomohl. Proces byl velmi jednoduchý a transparentní. Peníze byly na mém účtu velmi rychle. Děkuji!"]
		},
		{
			"id": 3,
			"rating": 4,
			"author": "Lucie M.",
			"date": "",
			"description": ["Mikroúvěr mi pomohl zaplatit nečekané účty. Proces byl velmi snadný a transparentní. Peníze byly na mém účtu velmi rychle. Velmi doporučuji!"]
		},
		{
			"id": 4,
			"rating": 4,
			"author": "Jan P.",
			"date": "",
			"description": ["Žádost o mikroúvěr byla velmi snadná a rychlá. Peníze byly na mém účtu velmi rychle. Velmi doporučuji!"]
		},
		{
			"id": 2,
			"rating": 4,
			"author": "Tereza S.",
			"date": "",
			"description": ["Mikroúvěr mi pomohl zaplatit nečekané účty. Proces byl velmi snadný a transparentní. Peníze byly na mém účtu velmi rychle. Velmi doporučuji!"]
		}
	]
}

export default data;
