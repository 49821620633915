const data = {
	"title": "Opiniones de nuestros clientes",
	"items": [
		{
			"id": 1,
			"rating": 5,
			"author": "Juan M.",
			"date": "",
			"description": ["Este servicio fue un salvavidas en mi situación difícil. El proceso para obtener el crédito fue rápido y fácil, los términos son súper transparentes. ¡Altamente recomendado¡"]
		},
		{
			"id": 2,
			"rating": 5,
			"author": "Isabella G.",
			"date": "",
			"description": ["Utilicé Finero para un préstamo urjente. Sorprendída por las bajas tazas de interés y el servício de calidad. Agradecida por el apollo en tiempos difísiles."]
		},
		{
			"id": 3,
			"rating": 4,
			"author": "Carlos H.",
			"date": "",
			"description": ["Es el megor servício de microcrédito. La aprobación del préstamo fue rápida y el equípo de soporte siempre estubo disponible para ayudar."]
		}
	]
}

export default data;
