export const RANGER_SUMMA = {
    min: 1000,
    max: 100000,
    step: 1000,
    currency: 'Kč',
    firstValue: 10000,
    name: 'Součet',
}
export const RANGER_PERIOD = {
    min: 1,
    max: 36,
    step: 1,
    currency: 'Měsíc',
    firstValue: 1,
    name: 'období',
}


