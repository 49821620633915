import React from 'react';
import ReactDOM from 'react-dom/client';
import {Helmet} from "react-helmet";
import {App} from "./App";
import {Provider} from "react-redux";
import { store } from './store';
import { getRootMetadata } from './utils/getRootMetadata';
import './index.sass';
import { getGoogleScripts } from './utils/getGoogleScripts';
import { LoadAnalyticsScripts } from './components/LoadAnalyticScripts/LoadAnalyticScripts';

const {
  title
} = getRootMetadata();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const region = process.env.REACT_APP_SITE_REGION as any;

const {
  scriptName,
  gaScriptUrl,
  gaID,
} = getGoogleScripts(region);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        {/* @ts-ignore */}
        <script id="google-analytics" strategy="afterInteractive">
          {``}
        </script>
        {/* @ts-ignore */}
        <script id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gaID}');`}
        </script>
        {/* @ts-ignore */}
        <script id="transaction-id-script" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            const transactionID = Math.random().toString(36).substr(2, 9) + Date.now();
            window.dataLayer.push({
                event: 'lead',
                transaction_id: transactionID
              });

            (function(b,c,h,p,q,e,r,w,d,k,t,a,l,m,f,n,u,g){function v(){for(var a=c.querySelectorAll(".dtpcnt"),b=0,d=a.length;b<d;b++)a[b][r]=a[b][r].replace(/(^|\\s+)dtpcnt($|\\s+)/g,"")}b[d]||(b[d]=function() {(b[d].q=b[d].q||[]).push(arguments)},m=c[p],c[p]=function() {m&&m.apply(this,arguments);if(b[d]&&!b[d].hasOwnProperty("params")&&/loaded|interactive|complete/.test(c.readyState))for(;a=c[q][k++];)/\\/?click($|(\\/[0-9]+)?$)/.test(a.pathname)&&(a[e]="javascrip"+b.postMessage.toString().slice(4,5)+":"+d+'.l="'+ a[e]+'",void 0')},setTimeout(function(){(n=/[?&]cpid(=([^&#]*)|&|#|$)/.exec(b.location.href))&&n[2]&&(f=n[2],u=c.cookie.match(new RegExp("(^| )vl-"+f+"=([^;]+)")));a=c.createElement("script");l=c.scripts[0];a.defer=1;a.src=t+(-1===t.indexOf("?")?"?":"&")+"lpref="+h(c.referrer)+"&lpurl="+h(location[e])+"&lpt="+h(c.title)+"&t="+(new Date).getTime()+(u?"&uw=no":"");a[w]=function(){for(k=0;a=c[q][k++];)/dtpCallback\\.l/.test(a[e])&&(a[e]=a[e].match(/dtpCallback\\.l="([^"]+)/)[1]);v()};l.parentNode.insertBefore(a, l);f&&(g=new Date,g.setTime(g.getTime()+864E5),c.cookie="vl-"+f+"=1; expires="+g.toGMTString()+"; path=/")},0),setTimeout(v,7E3))})(window,document,encodeURIComponent,"onreadystatechange","links","href","className","onerror","dtpCallback",0,"https://${gaScriptUrl}/d/${scriptName}?order_id=" + transactionID);
          `}
        </script>
      </Helmet>
      <LoadAnalyticsScripts gaID={gaID} />
      <App />
    </Provider>
  </React.StrictMode>
);
