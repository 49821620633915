import React from 'react';
import { useAppSelector } from '../../store';
import WhyIcon1 from '../../assets/images/svg/why/why_people.svg';
import WhyIcon2 from '../../assets/images/svg/why/why_money.svg';
import WhyIcon3 from '../../assets/images/svg/why/why_park.svg';
import WhyIcon4 from '../../assets/images/svg/why/wy_like.svg';
import google from '../../assets/images/google.png';
import trustpilot from '../../assets/images/trustpilot.png';
import './why.sass';
import { useInView } from 'react-intersection-observer';

const ICONS = [WhyIcon1, WhyIcon2, WhyIcon3, WhyIcon4];
const IMAGES = [google, trustpilot];

export function Why() {
  const config = useAppSelector(state => state.configs.general.data?.why);

  const { ref } = useInView({
    threshold: .2,
  });
  
  return (
    <section
      className="section why"
      ref={ref}
    >
      <div className="container why__container">
        <h2 className="section__title">{config?.title}</h2>
        <p className="section__description">{config?.description}</p>
        <div className="why__card-list">
          {(config?.card_list ?? []).map((card, idx) => (
              <div className="why__card" key={card.id}>
                <div className="why__card-img" style={{backgroundImage: `url('${ICONS[idx]}')`}}></div>
                <p className="why__card-description">{card.description}</p>
              </div>
          ))}
          {(config?.rating ?? []).map((card, idx) => (
              <a className="why__card why__card-review" href={card.link} key={card.id} style={{backgroundImage: `url('${IMAGES[idx]}')`}}>
                <div className="why__card-review-rate">
                  <svg xmlns="http://www.w3.org/2000/svg" width="88" height="16" viewBox="0 0 88 16" fill="none">
                    <path d="M80 0L82.116 5.08754L87.6085 5.52786L83.4238 9.11246L84.7023 14.4721L80 11.6L75.2977 14.4721L76.5762 9.11246L72.3915 5.52786L77.884 5.08754L80 0Z" fill="#FFEFD8"/>
                    <path d="M62 0L64.116 5.08754L69.6085 5.52786L65.4238 9.11246L66.7023 14.4721L62 11.6L57.2977 14.4721L58.5762 9.11246L54.3915 5.52786L59.884 5.08754L62 0Z" fill="#FFEFD8"/>
                    <path d="M44 0L46.116 5.08754L51.6085 5.52786L47.4238 9.11246L48.7023 14.4721L44 11.6L39.2977 14.4721L40.5762 9.11246L36.3915 5.52786L41.884 5.08754L44 0Z" fill="#FFEFD8"/>
                    <path d="M26 0L28.116 5.08754L33.6085 5.52786L29.4238 9.11246L30.7023 14.4721L26 11.6L21.2977 14.4721L22.5762 9.11246L18.3915 5.52786L23.884 5.08754L26 0Z" fill="#FFEFD8"/>
                    <path d="M8 0L10.116 5.08754L15.6085 5.52786L11.4238 9.11246L12.7023 14.4721L8 11.6L3.29772 14.4721L4.5762 9.11246L0.391548 5.52786L5.88397 5.08754L8 0Z" fill="#FFEFD8"/>
                    <mask id="mask0_46_1310" maskUnits="userSpaceOnUse" x="0" y="0" width="88" height="15">
                      <path d="M80 0L82.116 5.08754L87.6085 5.52786L83.4238 9.11246L84.7023 14.4721L80 11.6L75.2977 14.4721L76.5762 9.11246L72.3915 5.52786L77.884 5.08754L80 0Z" fill="#D9D9D9"/>
                      <path d="M62 0L64.116 5.08754L69.6085 5.52786L65.4238 9.11246L66.7023 14.4721L62 11.6L57.2977 14.4721L58.5762 9.11246L54.3915 5.52786L59.884 5.08754L62 0Z" fill="#D9D9D9"/>
                      <path d="M44 0L46.116 5.08754L51.6085 5.52786L47.4238 9.11246L48.7023 14.4721L44 11.6L39.2977 14.4721L40.5762 9.11246L36.3915 5.52786L41.884 5.08754L44 0Z" fill="#D9D9D9"/>
                      <path d="M26 0L28.116 5.08754L33.6085 5.52786L29.4238 9.11246L30.7023 14.4721L26 11.6L21.2977 14.4721L22.5762 9.11246L18.3915 5.52786L23.884 5.08754L26 0Z" fill="#D9D9D9"/>
                      <path d="M8 0L10.116 5.08754L15.6085 5.52786L11.4238 9.11246L12.7023 14.4721L8 11.6L3.29772 14.4721L4.5762 9.11246L0.391548 5.52786L5.88397 5.08754L8 0Z" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_46_1310)">
                      <rect width="71" height="14" fill="#FF8008"/>
                    </g>
                  </svg>
                  <div>{card.rating}<span>/</span>5</div>
                </div>
                <div className="why__card-review-text">
                  <h3 className="why__card-review-title">{card.title}</h3>
                  <p className="why__card-review-description">{card.description}</p>
                </div>
              </a>
          ))}
        </div>
      </div>
    </section>
  );
}
