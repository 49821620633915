import React from 'react';
import classNames from "classnames";
import { INav } from './Nav.types';
import './nav.sass';
import { useAppSelector } from '../../store';

export function Nav({isOpen, openMenuBurger, activeLink}: INav) {
  const headerConfig = useAppSelector(state => state.configs.general.data?.header);
  
  function handleClick(activeLink:string, link:string) {
    openMenuBurger(false);
  }

  function bgHandleClick(e:any ) {
    if(e.target.classList.contains('nav')) openMenuBurger(false)
  }

  return (
      <nav className={classNames("nav", isOpen ? 'nav_active' : '')} onClick={(e) => bgHandleClick(e)}>
          <ul className="nav__list">
              {(headerConfig?.links ?? []).map(item => (
                  <li className={classNames("nav__item", activeLink === item.link ? 'active': '' )} key={item.link}>
                      <a
                        href={`/#${item.link}`}
                        className={classNames("nav__link", activeLink === item.link ? 'active': '' )}
                        onClick={()=>handleClick(activeLink, item.link)}>
                          {item.name}
                      </a>
                  </li>
              ))}
          </ul>
      </nav>
  );
}
