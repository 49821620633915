import { configs } from "../../configs";
import { IFormsConfigData } from "../../configs/forms/forms.types";
import { IGeneralConfig } from "../../configs/general/general.types";
import { IOffersData } from "../../configs/offers/offers.types";
import { IReviewData } from "../../configs/review/review.types";
import { TLDTypes } from "../../constants/constantApi";
import { getDomain } from "../../utils/getTld";
import { apiService } from "../AxiosService";

const address = window.location.href;

type ConfigFileType = 'general' | 'forms' | 'review' | 'offers';

const getConfigUrl = (tld: string, type: ConfigFileType) => {
	return `https://conf.leadsor.com/${tld}/${type}.json?t=${Date.now()}`;
}

const tld: TLDTypes = getDomain(address) as TLDTypes;

export const getGeneralConfigs = async (): Promise<IGeneralConfig> => {
	const defaultConfig = configs.general[tld];
	const configUrl = getConfigUrl(tld, 'general');
	
	try {
		const response = await apiService.get(configUrl);
		const data = response?.data;

		const res: IGeneralConfig = data && typeof data === 'object'
			? response.data
			: defaultConfig;

		return res;
	} catch (e) {
		return defaultConfig;
	}
};

export const getReviews = async (): Promise<IReviewData> => {
	const defaultConfig = configs.reviews[tld];
	const configUrl = getConfigUrl(tld, 'review');

	try {
		const response = await apiService.get(configUrl);
		const data = response?.data;

		const res: IReviewData = data && typeof data === 'object'
			? response.data
			: defaultConfig;
			
		return res;
	} catch (e) {
		return defaultConfig;
	}
};

export const getFormsConfigData = async (): Promise<IFormsConfigData> => {
	const defaultConfig = configs.forms[tld];
	const configUrl = getConfigUrl(tld, 'forms');

	try {
		const response = await apiService.get(configUrl);
		const data = response?.data;

		const res: IFormsConfigData = data && typeof data === 'object'
			? response.data
			: defaultConfig;

		return res;
	} catch (e) {
		return defaultConfig;
	}
};

export const getOffers = async (): Promise<IOffersData> => {
	const defaultConfig = configs.offers[tld] as IOffersData;
	const configUrl = getConfigUrl(tld, 'offers');

	try {
		const response = await apiService.get(configUrl);
		const data = response?.data;

		const res: IOffersData = data && typeof data === 'object'
			? response.data
			: defaultConfig;

		return res;
	} catch (e) {
		return defaultConfig;
	}
};
