import React from 'react';
import './primarybutton.sass';
import { IButton } from './PrimaryButton.types';

export function PrimaryButton({text, onClick}: IButton) {
  const onClickHandle = () => {
    onClick && onClick();
  }

  return (
    <button
      className="btn btn-primary"
      onClick={onClickHandle}
    >
      {text}
    </button>
  );
}
