import React, {useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import {useInView} from "react-intersection-observer";
import {NAV_LINK_2, RANGE_LINK} from "../../constants/constantApi";
import {updateMoney} from "../../store/features/mainData/mainDataSlice";
import {ISetActiveLink} from "../../hooks/useObserver";
import forWhat1 from '../../assets/images/svg/forWhat/forwhat_bank.svg';
import forWhat2 from '../../assets/images/svg/forWhat/forwhat_wallet.svg';
import forWhat3 from '../../assets/images/svg/forWhat/forwhat_medicine.svg';
import forWhat4 from '../../assets/images/svg/forWhat/forwhat_airplane.svg';
import './forwhat.sass';

const USD_RATE = 23;

const IMAGES = [forWhat1, forWhat2, forWhat3, forWhat4];

export function ForWhat({setActive}: ISetActiveLink) {
  const dispatch = useAppDispatch();
  
  const config = useAppSelector(state => state.configs.general.data?.forWhat);

  const { ref, inView } = useInView({
    threshold: .2,
  });

  function handleClick(USD:number) {
    dispatch(updateMoney(USD * USD_RATE))
  }

  useEffect(() => {
    if (inView) setActive(NAV_LINK_2)
  }, [inView]);

  return (
    <section
      className='section for-what container'
      id={NAV_LINK_2}
      ref={ref}
    >
      <div className="container for-what__container negative">
        <div className="for-what__first">
          <h2 className='section__title for-what__first-title'>{config?.descriptionBlock.title}</h2>
          <div className="for-what__first-container" key={1}>
            {(config?.descriptionBlock.card_list ?? []).map((card, idx) => (
              <div className='for-what__first-card' key={card.id}>
                <div className="for-what__first-img" style={{backgroundImage: `url('${card.icon ?? IMAGES[idx]}')`}} />
                <p className="for-what__first-description">{card.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="for-what__second">
          <h2 className='section__title'>{config?.priceBlock.title}</h2>
          <div className="for-what__second-container" key={2}>
              {(config?.priceBlock.buttons ?? []).map(button => (
                    <a
                      href={`#${RANGE_LINK}`}
                      className="for-what__second-button"
                      key={button.value}
                      onClick={() => handleClick(button.value)}
                    >
                      {button.title}
                    </a>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
