import { IGeneralConfig } from "../general.types";
import hero from './img/hero__img.webp';
import banner from './img/banner.webp';

const data: IGeneralConfig = {
	"common": {
		"primaryButtonText": "Požádejte o půjčku",
		"rangeData": {
			"summa": {
				"min": 1000,
				"max": 100000,
				"step": 1000,
				"currency": "Kč",
				"firstValue": 10000,
				"name": "Součet"
			},
			"period": {
				"min": 1,
				"max": 36,
				"step": 1,
				"currency": "Měsíc",
				"firstValue": 1,
				"name": "období"
			}
		},
		"steps": [
			"KONTAKTNÍ ÚDAJE", "ZÁKLADNÍ ÚDAJE", "Dokončení žádosti"
		]
	},
	"header": {
		"logo": "",
		"links": [
			{
				"name": "Jak získat půjčku",
				"link": "hero"
			},
			{
				"name": "Jak to funguje",
				"link": "how-works"
			},
			{
				"name": "Časté dotazy",
				"link": "faq"
			},
			{
				"name": "Kontakty",
				"link": "contacts"
			}
		]
	},
	"main": {
		"title": "Opravdu rychlá půjčka na cokoliv.",
		"description": [
			"Žádost vyplníte během jedné minuty.",
			"Neprodleně vám sdělíme, je-li půjčka schválena.",
			"Vyřízení půjčky je zcela zdarma.",
			"Vaše osobní údaje jsou maximálně zabezpečené."
		],
		"img": {
			url: hero,
			alt: 'main image'
		},
		"rangeBlock": {
			"title": "Půjčka do 10 minut",
			"description": "Požádejte o půjčku do 10 minut",
			"value": "Součet:",
			"period": "Období",
			"return": "Vrátit:",
			"date": "Na rande"
		},
		"answerBlock": [
			{
				"id": 1,
				"title": "Kdo to může získat?",
				"description": "Soukromá osoba, 18+ s mobilním telefonem a bankovním účtem."
			},
			{
				"id": 2,
				"title": "Kdy budou peníze k dispozici?",
				"description": "Za 25 minut."
			},
			{
				"id": 3,
				"title": "Kdy mohu přijímat?",
				"description": "Okamžitě nebo druhý den v závislosti na bance."
			}
		]
	},
	"why": {
		"title": "Proč my",
		"description": "Na evropském trhu působíme již řadu let, vystavili jsme obrovské množství úvěrů a zde jsou naše výhody:",
		"card_list": [
			{
				"id": 1,
				"description": "Půjčka pro každého (pro všechny druhy příjmů, s dluhy)"
			},
			{
				"id": 2,
				"description": "Na evropském trhu je od roku 2008 schváleno více než 10 tisíc úvěrů"
			},
			{
				"id": 3,
				"description": "Máme jedny z nejrychlejších výplat na evropském"
			},
			{
				"id": 4,
				"description": "S pomocí našich partnerů nabízíme nejziskovější variantu."
			}
		],
		"rating": [
			{
				"id": 1,
				"rating": 4.1,
				"title": "Naše hodnocení v \"Trustpilot\"",
				"description": "Na základě 414 recenzí",
				"link": ""
			},
			{
				"id": 2,
				"rating": 4.1,
				"title": "",
				"description": "Půjčka pro každého (pro všechny druhy příjmů, s dluhy)",
				"link": ""
			}
		]
	},
	"footer": {
		"logo": "",
		"links": [
			{
				"id": 1,
				"title": "Información obligatoria",
				"link": ""
			}
		],
		"text": [
			{
				"title": "",
				"description": [
					"Finero ofrece préstamos flexibles que pueden ser devueltos en un plazo de 61 a 90 días, CAT mínima es de 0% y la máxima es de 36%. Por ejemplo, para un préstamo de $1000 a devolver en 67 días, los costos serán de $0 pesos, el importe total a devolver será de $1000 con una CAT de 0%. Es importante tener en cuenta que esta información no constituye una oferta de contrato. Se trata simplemente de un ejemplo representativo de un préstamo, y los datos son puramente informativos. El solicitante recibirá las condiciones exactas y tasas de interés del proveedor de préstamos específico después de completar la solicitud sin compromiso. Siempre es recomendable solicitar información sobre la tasa antes de firmar el contrato."
				]
			},
			{
				"title": "Préstamo vencido",
				"description": [
					"Si se encuentra en incumplimiento o sabe que no será capaz de cumplir con el pago del préstamo a tiempo, le rogamos que se ponga en contacto con su proveedor de crédito. Las penalizaciones y otras sanciones detalladas en el contrato de crédito están especificadas en las condiciones crediticias del proveedor en particular."
				]
			},
			{
				"title": "Transferencia de dinero",
				"description": [
					"La transferencia de dinero se efectúa en un período de 15 minutos después de la aprobación. La velocidad en la que se acreditan los fondos en su cuenta depende del banco con el que opere."
				]
			},
			{
				"title": "Información sobre el intermediario",
				"description": [
					"Este sitio web está vinculado a un sistema automatizado para buscar servicios financieros, especialmente préstamos u otros servicios financieros análogos."
				]
			},
			{
				"title": "Procedimiento interno para la resolución de reclamaciones",
				"description": [
					"En caso de cualquier reclamación, por favor háganoslo saber enviando un correo electrónico a admin@leadsor.co.uk, donde atenderemos su solicitud lo más pronto posible, pero no más tarde de 30 días, principalmente a través de una respuesta a su correo electrónico."
				]
			}
		],
		"copyright": [
			"Este sitio web utiliza cookies. El uso continuado de este sitio web implica su consentimiento. Si no está de acuerdo, puede optar por no utilizar cookies. Para más información, por favor, haga clic aquí.",
			"Copyright © 2024. Finero. Todos los derechos reservados."
		]
	},
	"banner": {
		"title": "Pojďme zlepšit vaši kreditní historii",
		"description": [
			"Pomůžeme vám zlepšit nebo vytvořit vaši kreditní historii od nuly!",
			"Můžete si vzít dlouhodobý úvěr za výhodnějších podmínek!"
		],
		"img": banner,
	},
	"forWhat": {
		"descriptionBlock": {
			"title": "Na co můžete využít online půjčku?",
			"card_list": [
				{
					"id": 1,
					"title": "Splatit dluh"
				},
				{
					"id": 2,
					"title": "Kombinujte více kreditů"
				},
				{
					"id": 3,
					"title": "Léčebné výlohy"
				},
				{
					"id": 4,
					"title": "Dovolená nebo cestování"
				}
			]
		},
		"priceBlock": {
			"title": "Kolik peněz potřebujete",
			"buttons": [
				{
					"title": "100 euro",
					"value": 100
				},
				{
					"title": "200 euro",
					"value": 200
				},
				{
					"title": "300 euro",
					"value": 300
				},
				{
					"title": "500 euro",
					"value": 500
				},
				{
					"title": "1000 euro",
					"value": 1000
				},
				{
					"title": "1500 euro",
					"value": 1500
				},
				{
					"title": "2000 euro",
					"value": 2000
				},
				{
					"title": "3000 euro",
					"value": 3000
				}
			]
		}
	},
	"about": {
		"title": "Vše, co o nás potřebujete vědět",
		"description": [
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.", "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
		],
		"tableData": {
			"head": {
				"id": 1,
				"question": "Co potřebuješ vědět",
				"comment": "Proč používat nás?"
			},
			"body": [
				{
					"id": 2,
					"question": "Kolik si mohou noví zákazníci půjčit?",
					"answer": "Až 5000 eur",
					"comment": "Žádné poplatky, nikdy!"
			  },
				{
					"id": 3,
					"question": "Jaké jsou podmínky splácení?",
					"answer": "3 až 36 měsíců",
					"comment": "Žádné papírování"
			  },
				{
					"id": 4,
					"question": "Reprezentativní RPSN",
					"answer": "49,9 % (proměnná)",
					"comment": "Žádná závazná nabídka"
			  },
				{
					"id": 5,
					"question": "Mohu požádat se špatným kreditem?",
					"answer": "Ano",
					"comment": "Okamžité rozhodnutí"
			  },
				{
					"id": 6,
					"question": "Mohu splácet ve splátkách",
					"answer": "Ano",
					"comment": "Flexible repayment"
			  },
				{
					"id": 7,
					"question": "Provádějí věřitelé úvěrovou kontrolu?",
					"answer": "Ano",
					"comment": "Provádíme pouze soft-search"
			  }
			]
		}
	},
	"faq": {
		"title": "Otázky a odpovědi",
		"data": [
			{
				"id": 1,
				"title": "Mohu použít svůj chytrý telefon k vyplnění žádosti o půjčku?",
				"description": "Ano, při vyřízení úvěru vám postačí mít u sebe pouze občanský průkaz, proto formulář můžete snadno vyplnit pomoci svého smartphonu, počítače nebo tabletu."
			},
			{
				"id": 2,
				"title": "Je vaše služba zdarma?",
				"description": "Veškeré naše služby jsou pro vás zcela zdarma. Za používání naší služby vám NIKDY nebudou účtovány žádné poplatky."
			},
			{
				"id": 3,
				"title": "Mám záznam v registru dlužníků, mohu získat půjčku?",
				"description": "Záznam v registru nemusí být nutně překážkou pro kladné vyřízení vaší žádosti. Stále máte šanci získat úvěr i v případě, když Vás jiné finanční instituce odmítnou. Nicméně i u nás se provádí posouzení úvěruschopnosti spotřebitele, zda jste úvěr schopni splatit."
			},
			{
				"id": 4,
				"title": "Budete mi účtovat další poplatky?",
				"description": "Naše společnost nevyžaduje žádný poplatek za používání našich služeb. Ani za půjčku se neplatí žádný poplatek předem."
			},
			{
				"id": 5,
				"title": "Kdy obdržím peníze? *",
				"description": "Peníze Vám budou zaslány hned po vyřízení a schválení půjčky. Peníze můžete mít už do několika minut, nejpozději pak druhý den. Vše záleží na tom, u jaké banky máte veden bankovní účet. V největších českých bankách peníze obdržíte ihned od schválení."
			},
			{
				"id": 6,
				"title": "Na co mohu půjčku použít?",
				"description": "Jedná se o nebankovní úvěr, proto můžete peníze použít na cokoliv a není třeba uvádět důvod k čemu peníze potřebujete."
			},
			{
				"id": 7,
				"title": "Mohu půjčku předčasně splatit?",
				"description": "Ano, vždy lze předčasné splatit vaši půjčku. Zaplatíte jen půjčenou částku a úroky."
			},
			{
				"id": 8,
				"title": "Potřebuji ručitele?",
				"description": "Ne, pro získání půjčky nepotřebujete ručitele."
			}
		]
	},
	"congratulation": {
		"title": "Probíhá vyhledávání nabídek",
		"description": "V tuto chvíli náš systém vyhledává vhodnou nabídku právě pro Vás.",
		"clockTitle": "Vydržte prosím"
	},
	"analytics": {
		"googleAnalyticsScript": "<Script id=\"google-analytics\" strategy=\"beforeInteractive\">(function(b,c,h,p,q,e,r,w,d,k,t,a,l,m,f,n,u,g){function v(){for(var a=c.querySelectorAll('.dtpcnt'),b=0,d=a.length;b<d;b++)a[b][r]=a[b][r].replace(/(^|\\s+)dtpcnt($|\\s+)/g,'')}b[d]||(b[d]=function() {(b[d].q=b[d].q||[]).push(arguments)},m=c[p],c[p]=function() {m&&m.apply(this,arguments);if(b[d]&&!b[d].hasOwnProperty('params')&&/loaded|interactive|complete/.test(c.readyState))for(;a=c[q][k++];)/\\/?click($|(\\/[0-9]+)?$)/.test(a.pathname)&&(a[e]='javascrip'+b.postMessage.toString().slice(4,5)+':'+d+'.l=\"'+ a[e]+'\",void 0')},setTimeout(function(){(n=/[?&]cpid(=([^&#]*)|&|#|$)/.exec(b.location.href))&&n[2]&&(f=n[2],u=c.cookie.match(new RegExp('(^| )vl-'+f+'=([^;]+)')));a=c.createElement('script');l=c.scripts[0];a.defer=1;a.src=t+(-1===t.indexOf('?')?'?':'&')+'lpref='+h(c.referrer)+'&lpurl='+h(location[e])+'&lpt='+h(c.title)+'&t='+(new Date).getTime()+(u?'&uw=no':'');a[w]=function(){for(k=0;a=c[q][k++];)/dtpCallback\\.l/.test(a[e])&&(a[e]=a[e].match(/dtpCallback\\.l=\"([^\"]+)/)[1]);v()};l.parentNode.insertBefore(a, l);f&&(g=new Date,g.setTime(g.getTime()+864E5),c.cookie='vl-'+f+'=1; expires='+g.toGMTString()+'; path=/')},0),setTimeout(v,7E3))})(window,document,encodeURIComponent,'onreadystatechange','links','href','className','onerror','dtpCallback',0,'https://track.finero.mx/d/75a21d79-ef33-451e-a2d7-46d787bc51e8.js');</Script>",
		"googleTagManagerScript": "<Script id=\"google-tag-manager\" strategy=\"afterInteractive\">(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-MV3S9P8L');</Script>",
		"googleTagManagerNoScript": "<noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MV3S9P8L\" height=\"0\" width=\"0\" style=\"display: none; visibility: hidden;\"></iframe></noscript>"
	}
}

export default data;
