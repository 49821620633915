import { URL_USER_EVENTS } from "../../../assets/helpers/constantAPI";
import { apiService } from "../../AxiosService";

export function sendUserAction<T>(
	clickId: string | null,
	options: { name: string, value: T | T[] }[],
) {
	if (clickId) {
		apiService.post(URL_USER_EVENTS, { clickId, options })
			.catch((e) => console.error(e))
	}
}