import { useEffect, useState } from "react";

export const useTimer = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (seconds >= 60) {
      setSeconds(0);
      setMinutes((prevMinutes) => prevMinutes + 1);
    }
  }, [seconds]);

  useEffect(() => {
    if (minutes >= 60) {
      setMinutes(0);
      setHours((prevHours) => prevHours + 1);
    }
  }, [minutes]);

  const getTotalTime = (hours: number, minutes: number, seconds: number) => {
    return hours * 60 * 60 + minutes * 60 + seconds;
  }

  return {
    hours,
    minutes,
    seconds,
    totalTime: getTotalTime(hours, minutes, seconds),
  }
}