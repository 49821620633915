import { TLD } from "../constants/constantApi";

export const getDomain = (url: string) => {
	try {
		const parsedUrl = new URL(url);
		const host = parsedUrl.host;

		if (host.includes('offers.finero.kz') || host.includes('loans.finero.kz')) {
			return TLD.kz;
		}

		if (host.includes('offers-kz.finero.kz') || host.includes('loans-kz.finero.kz')) {
			return TLD.kz_kz;
		}

		if (host.includes('offers.finero.mx') || host.includes('loans.finero.mx')) {
			return TLD.mx;
		}

		return TLD.cz;
	} catch (e) {
		return TLD.cz;
	}
}

export function getTLD(url: string){
	try {
			const parsedUrl = new URL(url);
			const hostname = parsedUrl.hostname;
			const parts = hostname.split('.');

			return parts.length > 1 ? parts.pop() : undefined;
	} catch (error) {
			console.error('Некорректный URL:', error);
			return undefined;
	}
}
