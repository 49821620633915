type EmploymentData = {
    [key: string]: string[];
};

export const EMPLOY_DATA: EmploymentData = {
    all: ["name_of_employer", "employer_addr", "employer_phone", "job_position", "how_long_had_income", "company_number", "employer_description",],
    EMPLOYED: ['name_of_employer', 'employer_addr', 'employer_phone', 'job_position', 'how_long_had_income'],
    PART_TIME_EMPLOYMENT: ['name_of_employer', 'employer_addr', 'employer_phone', 'job_position', 'how_long_had_income'],
    SELF_EMPLOYED: ['name_of_employer', 'employer_addr', 'employer_phone', 'how_long_had_income'],
    MATERNITY_LEAVE: [],
    PENSION: [],
    STUDENT: ['name_of_employer'],
    UNEMPLOYED: [],
    OTHER: ['job_position', 'employer_description']
}

export const getInputsForm = (titles: string[]) => [
    [{
        title: titles[0],
        inputs: ['first_name', 'last_name', 'email', 'phone', 'agreement', 'marketing_checkbox',],
    }],
    [{
        title: titles[1],
        inputs: ['soc_sec_number', 'zip_code', 'city', 'street', 'house_number', 'different_address', 'different_zip', 'different_city', 'different_street', 'different_house_number', 'home_status', 'src_of_income', 'name_of_employer', 'employer_addr', 'employer_phone', 'job_position', 'how_long_had_income', 'employer_description', 'your_income'],
    }],
    [{
        title: titles[2],
        inputs: ['op_number', 'marital_status', 'number_of_supp_children', 'home_status', 'company_number', 'your_expenses'],
    },
    {
        title: titles[3],
        inputs: ['bank_acc_number', 'bank_code'],
    }],
]
