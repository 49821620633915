import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RANGER_PERIOD, RANGER_SUMMA } from "../../../utils/defaultOptions";
import { LocalStorageService } from "../../../utils/localStorageService";
import { IObject } from "../../../utils/types";
import { IGeneralConfig } from "../../../configs/general/general.types";

export const getInitialValues = () => {
	const generalConfig = LocalStorageService.get<IGeneralConfig, null>('generalConfig', null);

	if (generalConfig) {
		const data = generalConfig.common;
		return { money: data.rangeData.summa.firstValue, period: data.rangeData.period.firstValue }
	}

	return { money: RANGER_SUMMA.firstValue, period: RANGER_PERIOD.firstValue };
}

export interface IInitialState {
	step: number,
	oldStep: number;
	money: number;
	period: number;
	stepsLength: number;
	urlData: IObject<string>;
	activeLink: string;
}

export const initialState: IInitialState = {
	step: 1,
	oldStep: 0,
	money: getInitialValues().money,
	period: getInitialValues().period,
	stepsLength: 3,
	urlData: {
		status_url: '',
		url: '',
	},
	activeLink: '',
}

export const mainDataSlice = createSlice({
	name: 'mainData',
	initialState,
	reducers: {
		updateStep(state, action: PayloadAction<number>) {
			state.step = action.payload;
		},

		updateOldStep(state, action: PayloadAction<number>) {
			state.oldStep = action.payload;
		},

		updateMoney(state, action: PayloadAction<number>) {
			state.money = action.payload;
		},

		updatePeriod(state, action: PayloadAction<number>) {
			state.period = action.payload;
		},

		setUrlPending(
			state,
			action: PayloadAction<{
				status_url: string,
				url: string,
			}>
		) {
			state.urlData = action.payload;
		},
		setActiveLink(state, action: PayloadAction<string>) {
			state.activeLink = action.payload;
		}
	}
});

export const {
  updateMoney,
	updateOldStep,
	updatePeriod,
	updateStep,
	setUrlPending,
	setActiveLink,
} = mainDataSlice.actions;

export default mainDataSlice.reducer;