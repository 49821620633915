export class LocalStorageService {
  // saving data to storage
  static set<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error setting ${key} in storage`, error);
    }
  }

  // getting data from storage
  static get<T, D>(key: string, defaultValue: D): T | D {
    try {
      const serializedValue = localStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue) as T : defaultValue;
    } catch (error) {
      console.error(`Error getting ${key} from storage`, error);
      return defaultValue;
    }
  }

  // removing data from storage
  static remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from storage`, error);
    }
  }
}

// examples for using
// StorageService.set<UserData>('user', { name: 'John Doe', age: 30 });

// const userData = StorageService.get<UserData>('user');
// console.log(userData);

// StorageService.remove('user');