import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { sendUserAction } from "./sendUserAction";
import { useAppSelector } from "../../../store";
import { useTimer } from "../../../hooks/useTimer";
import { sendScrollPosition } from "./handlers";
import { TIME_SPENT_PERIOD } from "../../../constants";

export const useSendRangeDataAction = (money: number, period: number) => {
	const clickID = useAppSelector(state => state.userData.clickID);

  const debouncedMoneyValue = useDebounce(money, 5000);
  // const debouncedPeriodValue = useDebounce(period, 500);
  const isInitialMoney = useRef(true);
  // const isInitialPeriod = useRef(true);

  useEffect(() => {
    if (debouncedMoneyValue && !isInitialMoney.current) {
      sendUserAction<number>(clickID, [{ name: 'amount', value: debouncedMoneyValue }]);
    }
  }, [clickID, debouncedMoneyValue]);

  // useEffect(() => {
  //   if (!isInitialPeriod.current) {
  //     sendUserAction<number>(clickID, { name: 'amountPeriod', values: debouncedPeriodValue });
  //   }
  // }, [clickID, debouncedPeriodValue]);

  return {
    isInitialMoney,
    // isInitialPeriod,
  }
}

export const useBlockOverSend = (
  inView: boolean,
  blockId: string | number,
) => {
  const [isBlockOverSended, setIsBlockOverSended] = useState(false);

  const clickID = useAppSelector(state => state.userData.clickID);

  useEffect(() => {
    if (!inView) setIsBlockOverSended(false);
  }, [inView])

  const onSendActionHandler = () => {
    if (inView && !isBlockOverSended) {
      sendUserAction<boolean>(clickID, [{ name: `isBlock_${blockId}`, value: true }]);
      setIsBlockOverSended(true);
    }
  }

  return onSendActionHandler;
}

export const useSendDelayOnOfferAction = (clickID: string | null) => {
  const [time, setTime] = useState(0);
  const [name, setName] = useState('');
  const [delay, setDelay] = useState(0);

  const timer = useRef<any>(undefined);

  useEffect(
    () => {
      if (delay) {
        timer.current = setTimeout(() => {
          setTime(time + 1);
        }, delay);
      }

      return () => {
        clearTimeout(timer.current);
      };
    },
    [delay, time]
  );

  useEffect(() => {
    if ((time !== 0 && delay !== 0) && (time * 1000) === delay) {
      sendUserAction<string | boolean>(clickID, [{ name, value: true }]);

      clearTimeout(timer.current);
      setTime(0);
      setDelay(0);
      setName('');
    }
  }, [time, delay, clickID, name]);

  const onAction = (name: string, delay: number, time = 0) => {
    setName(name);
    setDelay(delay);
    setTime(time);
  }

  const onStopTimer = () => {
    clearTimeout(timer.current);
    setName(name);
    setDelay(delay);
    setTime(time);
  }

  return { onAction, onStopTimer };
}

export const useSendScrollPosition = (clickId: string | null, eventType: string) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const { totalTime } = useTimer();

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPosition = (scrollTop / docHeight) * 100;
    setScrollPercentage(scrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (clickId && totalTime > 0 && totalTime % TIME_SPENT_PERIOD === 0) {
      sendScrollPosition(clickId, eventType, scrollPercentage);
    }
  }, [clickId, totalTime]);
}