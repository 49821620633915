import React, {useEffect} from 'react';
import classNames from "classnames";
import { IOpenMenu } from '../../components/Header/Header.types';
import './burger.sass';

export function Burger({isOpen, openMenuBurger}: IOpenMenu) {
  function handleClick() {
    openMenuBurger(!isOpen)
  }

  useEffect(() => {
    const body = document.querySelector('body')
    if(body) {
      isOpen ? body.classList.add('lock') : body.classList.remove('lock');
    }
  },[isOpen])

  return (
    <div className={classNames("burger", isOpen ? 'burger_active' : '')} aria-label="Меню" onClick={handleClick}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
