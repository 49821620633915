import { IOffersItemBG, OffersItemBG } from "../../configs/offers/offers.types";

export const getBGImage = (value: string) => ({
  backgroundImage: `url('${value}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
})

export const getCardBG = (bgData?: IOffersItemBG) => {
  if (!bgData) return { background: '#fff' };

  const { type, value } = bgData;

  if (type === OffersItemBG.image) {
    return getBGImage(value);
  }

  return { background: value };
}
