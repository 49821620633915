const data = {
  "title": "Отзывы наших клиентов",
  "items": [
    {
      "id": 1,
      "rating": 5,
      "author": "Толкын",
      "date": "",
      "description": ["Finero — идеальное решение для тех, кто ищет быстрый и доступный микрокредит. Я очень доволен вашим сервисом."]
    },
    {
      "id": 2,
      "rating": 5,
      "author": "Виктор",
      "date": "",
      "description": ["Эта услуга стала спасением в моей сложной ситуации. Процесс получения микрокредита прошел быстро и легко, условия суперпрозрачные. Настоятельно рекомендуется."]
    },
    {
      "id": 3,
      "rating": 4,
      "author": "Болат",
      "date": "",
      "description": ["Я использовал Finero для получения срочного микрокредита. Удивили низкие процентные ставки и качество обслуживания. Благодарен за поддержку в трудную минуту."]
    },
    {
      "id": 4,
      "rating": 4,
      "author": "Ян П.",
      "date": "",
      "description": ["Заявка на микрокредит была очень простой и быстрой. Деньги поступили на мой счет очень быстро. Очень рекомендую!"]
    },
    {
      "id": 5,
      "rating": 4,
      "author": "Тереза С.",
      "date": "",
      "description": ["Микрокредит помог мне оплатить неожиданные счета. Процесс был очень простым и прозрачным. Деньги были на моем счете очень быстро. Очень рекомендую!"]
    }
  ]
}

export default data;
