import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IInitialState {
	clickID: string | null;
}

export const initialState: IInitialState = {
	clickID: null,
}

export const userSlice = createSlice({
	name: 'userData',
	initialState,
	reducers: {
		setClickID(state, action: PayloadAction<string>) {
			state.clickID = action.payload;
		},
	}
});

export const {
  setClickID,
} = userSlice.actions;

export default userSlice.reducer;