import React, {useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActiveLink } from '../../store/features/mainData/mainDataSlice';
import {Header} from "../Header";
import {Hero} from "../Hero";
import {Why} from "../Why";
import {Banner} from "../Banner";
import Review from "../Review/Review";
import {ForWhat} from "../ForWhat";
import Footer from "../Footer/Footer";
import {About} from "../About";
import {Faq} from "../Faq";
import { StateContainer } from '../../UI/StateContainer/StateContainer';
import { useSendScrollPosition } from '../../api/methods/sendUserAction/hooks';
import './firstpage.sass';

const contentRendering = (
  activeLink: string,
  setActiveLink: (event: string) => void,
) => {
  return (
    <>
      <Header activeLink={activeLink}/>
      <main className="main">
        <Hero setActive={setActiveLink}/>
        <Why />
        <Banner />
        <Review />
        <ForWhat setActive={setActiveLink} />
        <About />
        <Faq setActive={setActiveLink}/>
      </main>
      <Footer setActive={setActiveLink}/>
    </>
  )
}

export function FirstPage() {
  const dispatch = useAppDispatch();

  const {
    general: { isLoading, error, data },
  } = useAppSelector(state => state.configs);

  const clickId = useAppSelector(state => state.userData.clickID);

  const { activeLink } = useAppSelector(state => state.mainData);

  useSendScrollPosition(clickId, 'scroll_position');

  const setActive = useCallback((link: string) => {
    dispatch(setActiveLink(link));
  }, [dispatch]);

  const content = useMemo(() => contentRendering(
    activeLink,
    setActive,
  ), [activeLink, setActive]);

  return (
    <div className="first-page">
      {isLoading && <StateContainer text="Loading..." />}
      {!isLoading && (error || (!error && !data)) && (
        <StateContainer text="Something went wrong. Please, try again" />
      )}
      {!isLoading && !!data && content}
    </div>
  );
}
