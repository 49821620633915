import axios from 'axios';
import { IObject } from '../utils/types';

axios.interceptors.response.use(res => res,
	res => {
		const status = res?.response?.status;
	
		if (!status || status === 400 || status === 404 || status === 405) {
			const message = res.response?.message;

			const error = !!message
				? `: message`
				: '';
	
			throw new Error(`Bad request with code 404${error}`);
		}
	});

const get = (url: string) => {
	return axios.get(url);
};

const post = (url: string, options: string | IObject<any>) => {
	return axios.post(url, options);
}

export const apiService = {
	get,
	post,
};
