const data = {
	"items": [
		{
			"id": 1,
			"name": "first_name",
			"input_type": "text",
			"label": "Jméno",
			"placeholder": "Jméno",
			"validate": {
				"required": true,
				"minLength": 2,
				"pattern": {
					"reg": "/^[a-zA-Za-zA-ZÁáBbCcČčDdĎďEeÉéĚěFfGgHhChIiÍíJjKkLlMmNnŇňOoÓóPpQqRrŘřSsŠšTtŤťUuÚúŮůVvWwXxYyÝýZzŽž]+$/i",
					"message": "Pole může obsahovat pouze písmena \"a-ž\""
				}
			},
			"grid": 2
		},
		{
			"id": 2,
			"name": "last_name",
			"input_type": "text",
			"label": "Příjmení",
			"validate": {
				"required": true,
				"minLength": 1,
				"pattern": {
					"reg": "/^[a-zA-ZÁáBbCcČčDdĎďEeÉéĚěFfGgHhChIiÍíJjKkLlMmNnŇňOoÓóPpQqRrŘřSsŠšTtŤťUuÚúŮůVvWwXxYyÝýZzŽž-]+$/i",
					"message": "Pouze písmena bez číslic"
				}
			},
			"grid": 2
		},
		{
			"id": 3,
			"name": "email",
			"input_type": "text",
			"label": "E-mail",
			"description": "Na tento e-mail obdržíte všechny náležitosti k půjčce.",
			"validate": {
				"required": true,
				"pattern": {
					"reg": "^[a-zA-Z0-9]+@[a-zA-Z]+\\.[a-zA-Z]{2,4}$",
					"message": "Neplatná emailová adresa."
				}
			}
		},
		{
			"id": 4,
			"name": "phone",
			"input_type": "text",
			"label": "Mobile phone",
			"description": "Na toto číslo obdržíte v následujícím kroku kód, který Vám poslouží k ověření Vaší žádosti.",
			"validate": {
				"required": true,
				"pattern": {
					"reg": "/^[6-7][02379][0-9][\\s]?[0-9]{3}[\\s]?[0-9]{3}$/",
					"message": "Špatné telefonní číslo.",
					"mask": "999 999 999"
				}
			}
		},
		{
			"id": 5,
			"name": "agreement",
			"input_type": "checkbox",
			"label": "Zaškrtnutím uděluji souhlas se zpracováním osobních údajů za účelem vyřízení mé žádosti o zprostředkování půjčky.",
			"validate": {
				"required": true
			}
		},
		{
			"id": 6,
			"name": "marketing_checkbox",
			"input_type": "checkbox",
			"label": "Zaškrtnutím uděluji souhlas se zpracováním osobních údajů za účelem marketingové komunikace.",
			"validate": {}
		},
		{
			"id": 7,
			"name": "soc_sec_number",
			"input_type": "text",
			"label": "Rodné číslo",
			"placeholder": "880101/1954",
			"description": "Zadejte 10 číslic bez lomítka.",
			"validate": {
				"required": true,
				"maxLength": 11,
				"pattern": {
					"reg": "/^[0-9]{6}\\/?[0-9]{3,4}$/",
					"message": "Zadejte 10 číslic bez lomítka.",
					"mask": "999999/9999"
				}
			}
		},
		{
			"id": 8,
			"name": "zip_code",
			"input_type": "text",
			"label": "PSČ",
			"placeholder": "110 00",
			"validate": {
				"required": true,
				"pattern": {
					"reg": "/[1-7][0-9]{2}[\\s]?[0-9]{2}$/",
					"message": "Hodnota pole musí obsahovat 5 znaků a nesmí začínat 0, 8, 9",
					"mask": "999 99"
				}
			},
			"grid": 2
		},
		{
			"id": 9,
			"name": "city",
			"input_type": "text",
			"label": "Město / obec",
			"placeholder": "Praha",
			"validate": {
				"required": true
			},
			"grid": 2
		},
		{
			"id": 10,
			"name": "street",
			"input_type": "text",
			"label": "Ulice",
			"placeholder": "Jindřišská",
			"description": "Pokud ve Vaší obci nejsou ulice, zadejte opakovaně název obce.",
			"validate": {
				"required": true
			},
			"grid": 2
		},
		{
			"id": 11,
			"name": "house_number",
			"input_type": "text",
			"label": "Číslo",
			"placeholder": "1111/22",
			"description": "",
			"validate": {
				"required": true
			},
			"grid": 2
		},
		{
			"id": 12,
			"name": "different_address",
			"input_type": "checkbox",
			"label": "Mám jinou kontaktní adresu",
			"validate": {}
		},
		{
			"id": 13,
			"name": "different_zip",
			"input_type": "text",
			"label": "PSČ",
			"placeholder": "110 00",
			"validate": {
				"required": true,
				"pattern": {
					"reg": "/[1-7][0-9]{2}[\\s]?[0-9]{2}$/",
					"message": "Hodnota pole musí obsahovat 5 znaků a nesmí začínat 0, 8, 9",
					"mask": "999 99"
				}
			},
			"grid": 2
		},
		{
			"id": 14,
			"name": "different_city",
			"input_type": "text",
			"label": "Město / obec",
			"placeholder": "Praha",
			"validate": {
				"required": true
			},
			"grid": 2
		},
		{
			"id": 15,
			"name": "different_street",
			"input_type": "text",
			"label": "Ulice",
			"placeholder": "Jindřišská",
			"description": "Pokud ve Vaší obci nejsou ulice, zadejte opakovaně název obce.",
			"validate": {
				"required": true
			},
			"grid": 2
		},
		{
			"id": 16,
			"name": "different_house_number",
			"input_type": "text",
			"label": "Číslo",
			"placeholder": "1111/22",
			"description": "",
			"validate": {
				"required": true
			},
			"grid": 2
		},
		{
			"id": 34,
			"name": "home_status",
			"input_type": "radio",
			"label": "Druh bydlení",
			"validate": {
				"required": true
			},
			"grid": 3,
			"selects": [
				{
					"value": "TENANT",
					"label": "Nájem / podnájem"
				},
				{
					"value": "HOME_OWNER",
					"label": "Vlastní byt / dům"
				},
				{
					"value": "DORMITORY",
					"label": "Studentské koleje / hotel"
				},
				{
					"value": "EMPLOYEE_BENEFIT",
					"label": "Firemní byt"
				},
				{
					"value": "MINISTRY",
					"label": "Na úřadě"
				},
				{
					"value": "TENANTS",
					"label": "Jiný"
				}
			]
		},
		{
			"id": 17,
			"name": "src_of_income",
			"input_type": "radio",
			"label": "Zdroje příjmu",
			"placeholder": "",
			"validate": {
				"required": true
			},
			"grid": 3,
			"selects": [
				{
					"value": "EMPLOYED",
					"label": "Práce na plný úvazek"
				},
				{
					"value": "PART_TIME_EMPLOYMENT",
					"label": "Práce na částečný úvazek"
				},
				{
					"value": "SELF_EMPLOYED",
					"label": "OSVČ nebo podnikatel"
				},
				{
					"value": "MATERNITY_LEAVE",
					"label": "Mateřská / rodičovská dovolená"
				},
				{
					"value": "PENSION",
					"label": "Důchod"
				},
				{
					"value": "STUDENT",
					"label": "Brigáda"
				},
				{
					"value": "UNEMPLOYED",
					"label": "Nezaměstnaný"
				},
				{
					"value": "OTHER",
					"label": "Ostatní"
				}
			]
		},
		{
			"id": 18,
			"name": "your_income",
			"input_type": "number",
			"label": "Vaše příjmy",
			"placeholder": "14000",
			"validate": {
				"required": true
			},
			"description": "Prosím uveďte výši Vašeho čístého měsíčního příjmu (po zdanění)."
		},
		{
			"id": 19,
			"name": "op_number",
			"input_type": "text",
			"label": "Číslo OP",
			"placeholder": "200300200",
			"validate": {
				"required": true,
				"minLength": 9,
				"maxLength": 11,
				"pattern": {
					"reg": "/^[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}/",
					"message": "Hodnota pole musí obsahovat 9 znaků a nesmí začínat 0",
					"mask": "999999999"
				}
			}
		},
		{
			"id": 20,
			"name": "marital_status",
			"input_type": "radio",
			"label": "Rodinný stav",
			"validate": {
				"required": true
			},
			"grid": 3,
			"selects": [
				{
					"value": "SINGLE",
					"label": "Svobodný / svobodná"
				},
				{
					"value": "MARRIED",
					"label": "Ženatý / vdaná"
				},
				{
					"value": "PARTNERSHIP",
					"label": "Společná domácnost"
				},
				{
					"value": "DIVORCED",
					"label": "Rozvedený / rozvedená"
				},
				{
					"value": "WIDOWED",
					"label": "Vdovec / vdova"
				},
				{
					"value": "SEPARATED",
					"label": "Jiné"
				}
			]
		},
		{
			"id": 21,
			"name": "number_of_supp_children",
			"input_type": "radio",
			"label": "Počet vyživovaných dětí",
			"validate": {
				"required": true
			},
			"grid": 3,
			"selects": [
				{
					"value": "0",
					"label": "0"
				},
				{
					"value": "1",
					"label": "1"
				},
				{
					"value": "2",
					"label": "2"
				},
				{
					"value": "3",
					"label": "3"
				},
				{
					"value": "4",
					"label": "4"
				},
				{
					"value": "5+",
					"label": "Více než 4"
				}
			]
		},
		{
			"id": 22,
			"name": "home_status",
			"input_type": "radio",
			"label": "Druh bydlení",
			"validate": {
				"required": true
			},
			"grid": 3,
			"selects": [
				{
					"value": "TENANT",
					"label": "Nájem / podnájem"
				},
				{
					"value": "HOME_OWNER",
					"label": "Vlastní byt / dům"
				},
				{
					"value": "DORMITORY",
					"label": "Studentské koleje / hotel"
				},
				{
					"value": "EMPLOYEE_BENEFIT",
					"label": "Firemní byt"
				},
				{
					"value": "MINISTRY",
					"label": "Na úřadě"
				},
				{
					"value": "TENANTS",
					"label": "Jiný"
				}
			]
		},
		{
			"id": 23,
			"name": "company_number",
			"input_type": "text",
			"label": "IČ",
			"placeholder": "11122233",
			"description": "Zadejte prosím platné IČO pro ověření údajů v registru ARES.",
			"validate": {
					"maxLength": 8,
					"pattern": {
							"reg": "^[0-9]{8}$",
							"message": "Hodnota pole musí obsahovat 8 znaků",
							"mask": "99999999"
					}
			},
			"grid": 2
		},
		{
				"id": 24,
				"name": "name_of_employer",
				"input_type": "text",
				"label": "Název zaměstnavatele",
				"validate": {
						"required": true
				},
				"grid": 2,
				"description": "Zadejte název společnosti pro kterou pracujete."
		},
		{
				"id": 25,
				"name": "employer_addr",
				"input_type": "text",
				"label": "Adresa zaměstnavatele",
				"placeholder": "Praha, Sokolovská 111/1",
				"validate": {
						"required": true
				},
				"grid": 2
		},
		{
				"id": 26,
				"name": "employer_phone",
				"input_type": "text",
				"label": "Telefon zaměstnavatele",
				"placeholder": "602 123 123",
				"description": "Na toto číslo obdržíte v následujícím kroku kód, který Vám poslouží k ověření Vaší žádosti.",
				"validate": {
						"required": true,
						"pattern": {
								"reg": "^[6-7][0279][0-9][\\s]?[0-9]{3}[\\s]?[0-9]{3}$",
								"message": "Je vyžadováno správné telefonní číslo",
								"mask": "999 999 999"
						}
				},
				"grid": 2
		},
		{
				"id": 27,
				"name": "job_position",
				"input_type": "text",
				"label": "Pracovní pozice",
				"placeholder": "Manažer call centra",
				"validate": {
						"required": true
				}
		},
		{
				"id": 28,
				"name": "how_long_had_income",
				"input_type": "radio",
				"label": "Jak dlouho již máte tento příjem?",
				"validate": {
						"required": true
				},
				"grid": 3,
				"selects": [
						{
								"value": "1",
								"label": "1-3 měsíce"
						},
						{
								"value": "2",
								"label": "4-7 měsíců"
						},
						{
								"value": "3",
								"label": "7-12 měsíců"
						},
						{
								"value": "4",
								"label": "2 roky"
						},
						{
								"value": "5",
								"label": "3 roky"
						},
						{
								"value": "6",
								"label": "4 roky"
						},
						{
								"value": "7",
								"label": "5 let"
						},
						{
								"value": "8",
								"label": "Více než 5 let"
						}
				]
		},
		{
				"id": 29,
				"name": "employer_description",
				"input_type": "text",
				"label": "Popište svůj zdroj příjmu",
				"placeholder": "Příjmy z pronájmu",
				"validate": {
						"required": true
				}
		},
		{
				"id": 30,
				"name": "your_expenses",
				"input_type": "number",
				"label": "Vaše výdaje",
				"placeholder": "7000",
				"validate": {
						"required": true
				},
				"description": "Prosím uveďte zde Vaše průměrné měsíční výdaje."
		},
		{
				"id": 32,
				"name": "bank_acc_number",
				"input_type": "text",
				"label": "Číslo bankovního účtu",
				"placeholder": "15-123456789",
				"validate": {
						"required": true,
						"maxLength": 17,
						"pattern": {
								"reg": "^(([0-9]{0,6})-)?([0-9]{2,10})$",
								"message": "Neplatné číslo bankovního účtu"
						}
				},
				"grid": 2,
				"description": "Uveďte číslo Vašeho bankovního účtu. Na tento účet Vám bude zápůjčka zaslána."
		},
		{
			"id": 33,
			"name": "bank_code",
			"input_type": "select",
			"label": "Kód banky",
			"placeholder": "Vybrat...",
			"validate": {
					"required": true
			},
			"grid": 2,
			"selects": [
				{
					"value": "0100",
					"label": "0100-Komerční banka a. s."
				},
				{
						"value": "0300",
						"label": "0300-Československá obchodní banka a. s."
				},
				{
						"value": "0400",
						"label": "0400-Živnostenská banka a. s."
				},
				{
						"value": "0600",
						"label": "0600-GE Money Bank a. s."
				},
				{
						"value": "0710",
						"label": "0710-Česká národní banka"
				},
				{
						"value": "0800",
						"label": "0800-Česká spořitelna a. s."
				},
				{
						"value": "1600",
						"label": "1600-Pragobanka a. s."
				},
				{
						"value": "1900",
						"label": "1900-SOCIETE GENERALE BANKA a. s."
				},
				{
						"value": "2010",
						"label": "2010-Fio banka a. s."
				},
				{
						"value": "2020",
						"label": "2020-Bank of Tokyo-Mitsubishi UFJ (Holland) N.V. Prague Branch, organizační složka"
				},
				{
						"value": "2030",
						"label": "2030-AKCENTA, spořitelní a úvěrní družstvo"
				},
				{
						"value": "2050",
						"label": "2050-WPB Capital, spořitelní družstvo"
				},
				{
						"value": "2060",
						"label": "2060-Citfin, spořitelní družstvo"
				},
				{
					"value": "2070",
					"label": "2070-Moravský Peněžní Ústav – spořitelní družstvo"
				},
				{
						"value": "2100",
						"label": "2100-Hypoteční banka a. s."
				},
				{
						"value": "2200",
						"label": "2200-Peněžní dům, spořitelní družstvo"
				},
				{
						"value": "2210",
						"label": "2210-Evropsko-ruská banka a. s."
				},
				{
						"value": "2220",
						"label": "2220-Artesa, spořitelní družstvo"
				},
				{
						"value": "2230",
						"label": "2230-AXA Bank Europe, organizační složka"
				},
				{
						"value": "2240",
						"label": "2240-Poštová banka a. s., pobočka Česká republika"
				},
				{
						"value": "2250",
						"label": "2250-Záložna CREDITAS, spořitelní družstvo"
				},
				{
						"value": "2310",
						"label": "2310-ZUNO BANK AG, organizační složka"
				},
				{
						"value": "2400",
						"label": "2400-eBanka a. s."
				},
				{
						"value": "2500",
						"label": "2500-Interbanka a. s."
				},
				{
						"value": "2600",
						"label": "2600-Citibank Europe plc, organizační složka"
				},
				{
						"value": "2700",
						"label": "2700-UniCredit Bank Czech Republic a. s."
				},
				{
						"value": "2800",
						"label": "2800-Bank Austria (ČR) a. s."
				},
				{
						"value": "2865",
						"label": "2865-Preciosa - podniková banka"
				},
				{
						"value": "3030",
						"label": "3030-Air Bank a. s."
				},
				{
						"value": "3050",
						"label": "3050-Hello bank"
				},
				{
						"value": "3300",
						"label": "3300-Konsolidační banka Praha s. p. ú."
				},
				{
						"value": "3400",
						"label": "3400-UNION Banka a. s."
				},
				{
						"value": "3500",
						"label": "3500-ING Bank N.V."
				},
				{
						"value": "3800",
						"label": "3800-Vereinsbank (CZ) a. s."
				},
				{
						"value": "3900",
						"label": "3900-HYPO-BANK CZ a. s."
				},
				{
					"value": "4000",
					"label": "4000-LBBW Bank CZ a. s."
				},
				{
						"value": "4300",
						"label": "4300-Českomoravská záruční a rozvojová banka a. s."
				},
				{
						"value": "4400",
						"label": "4400-Erste Bank Sparkassen (ČR)"
				},
				{
						"value": "4500",
						"label": "4500-Moravia banka a. s."
				},
				{
						"value": "4600",
						"label": "4600-Plzeňská banka a. s."
				},
				{
						"value": "5000",
						"label": "5000-Credit Lyonnais Bank Praha a. s."
				},
				{
						"value": "5100",
						"label": "5100-Investiční a poštovní banka a. s."
				},
				{
						"value": "5400",
						"label": "5400-The Royal Bank of Scotland plc, organizační složka"
				},
				{
						"value": "5500",
						"label": "5500-Raiffeisenbank a. s."
				},
				{
						"value": "5700",
						"label": "5700-Velkomoravská banka a. s."
				},
				{
						"value": "5800",
						"label": "5800-J & T Banka a. s."
				},
				{
						"value": "6000",
						"label": "6000-PPF banka a. s."
				},
				{
						"value": "6100",
						"label": "6100-Equa bank a. s."
				},
				{
						"value": "6200",
						"label": "6200-Commerzbank AG"
				},
				{
						"value": "6210",
						"label": "6210-mBank S.A., organizační složka podniku"
				},
				{
						"value": "6300",
						"label": "6300-BNP Paribas Fortis SA/NV, pobočka Česká republika"
				},
				{
						"value": "6400",
						"label": "6400-Universal banka a. s."
				},
				{
						"value": "6700",
						"label": "6700-Všeobecná úverová banka a. s., pobočka Praha"
				},
				{
						"value": "6800",
						"label": "6800-Sberbank CZ a. s."
				},
				{
						"value": "7910",
						"label": "7910-Deutsche Bank A.G. Filiale Prag"
				},
				{
						"value": "7920",
						"label": "7920-Foresbank a. s."
				},
				{
						"value": "7940",
						"label": "7940-Waldviertler Sparkasse Bank AG"
				},
				{
						"value": "7950",
						"label": "7950-Raiffeisen stavební spořitelna a. s."
				},
				{
						"value": "7960",
						"label": "7960-Českomoravská stavební spořitelna a. s."
				},
				{
						"value": "7970",
						"label": "7970-Wüstenrot-stavební spořitelna a. s."
				},
				{
						"value": "7980",
						"label": "7980-Wüstenrot hypoteční banka a. s."
				},
				{
						"value": "7990",
						"label": "7990-Modrá pyramida stavební spořitelna a. s."
				},
				{
						"value": "8030",
						"label": "8030-Raiffeisenbank im Stiftland eG pobočka Cheb, odštěpný závod"
				},
				{
						"value": "8040",
						"label": "8040-Oberbank AG pobočka Česká republika"
				},
				{
						"value": "8046",
						"label": "8046-SMW Banka"
				},
				{
						"value": "8060",
						"label": "8060-Stavební spořitelna České spořitelny a. s."
				},
				{
						"value": "8070",
						"label": "8070-HYPO - stavební spořitelna a. s."
				},
				{
						"value": "8090",
						"label": "8090-Česká exportní banka a. s."
				},
				{
						"value": "8150",
						"label": "8150-HSBC Bank plc - pobočka Praha"
				},
				{
						"value": "8200",
						"label": "8200-PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich v České republice"
				}
			]
		}
	],
	"title": [
		"Základní údaje",
		"Vaše trvalé bydliště",
		"Vaše finanční situace",
		"Na jaký účet si přejete zaslat peníze?"
	],
	"buttons": {
		"next": "Pokračovat",
		"prev": "Zpět",
		"finish": "Odeslat žádost o půjčku"
	},
	"errors": {
		"required": "Údaj je povinný.",
		"min": "Hodnota musí být větší",
		"max": "Vyberte hodnotu nižší",
		"minLength": "Pole musí obsahovat více znaků",
		"maxLength": "Hodnota by měla být kratší",
		"pattern": "Hodnota není ve správném formátu"
	},
	"range": {
		"title": "Peníze už jsou téměř vaše",
		"description": "Kredit na 10 minut"
	},
	"stepsLength": 3,
}

export default data;
