import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGeneralConfig } from "../../../configs/general/general.types";
import { LocalStorageService } from "../../../utils/localStorageService";
import { LS_GENERAL_CONFIG_KEY } from "../../../configs/storageKeys";
import { getGeneralConfigs } from "../../../api/methods/getConfigs";

interface IConfigsStateItem<T> {
	data: null | T;
	error: null | string;
	isLoading: boolean;
}

interface IConfigsState {
	general: IConfigsStateItem<IGeneralConfig>;
}

const initialState: IConfigsState = {
	general: {
		data: null,
		error: null,
		isLoading: false,
	}
}

export const getGeneralConfig = createAsyncThunk(
	'configsSlice/getGeneralConfig',
	async (_, thunkApi) => {
		try {
			const cachedData = LocalStorageService.get<IGeneralConfig, null>(LS_GENERAL_CONFIG_KEY, null);

			if (cachedData) {
				return { data: cachedData };
			}
			
			const jsonData = await getGeneralConfigs();
			const	data = jsonData;

			LocalStorageService.set<IGeneralConfig>(LS_GENERAL_CONFIG_KEY, data);

			return { data };
		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
)

export const configsSlice = createSlice({
	name: 'mainData',
	initialState,
	reducers: {
		// setGeneralConfig(state, action: PayloadAction<IGeneralConfig>) {
		// 	state.general = action.payload;
		// },
	},
	extraReducers: (builder) => {
		builder.addCase(getGeneralConfig.pending, (state, action) => {
			state.general.isLoading = true;
		});

		builder.addCase(getGeneralConfig.fulfilled, (state, action) => {
			const { data } = action.payload;

			state.general.isLoading = false;
			state.general.data = data;
			state.general.error = null;
		});

		builder.addCase(getGeneralConfig.rejected, (state, action) => {
			state.general.isLoading  = false;
			state.general.error = action.payload as string;
		});
	}
});

export const {} = configsSlice.actions;

export default configsSlice.reducer;