import { IRanger } from './Ranger.types';
import './ranger.sass';
import {useEffect, useRef, useState} from "react";

export function Ranger({props, firstValue, onChange} : IRanger) {
  const [positionRange, setPositionRange] = useState(0)
  const ref = useRef<HTMLInputElement>(null);

  function setValuePositionRange(width: number) {
    let percent= ((firstValue - props.min) / (props.max - props.min)) * 100
    setPositionRange(percent * (width - 165) / 100) // ширина трэка
  }

  useEffect(() => {
      if(ref.current) {
        setValuePositionRange(ref.current.getBoundingClientRect().width)
      }
  },[firstValue])

  window.addEventListener('resize', () => {
    if(ref.current) {
      setValuePositionRange(ref.current.getBoundingClientRect().width)
    }
  })

  function setValueRange(sum: number) {
    onChange(sum)
  }
  function editValueRange(sum: number) {
    let newValue = firstValue + sum
      if(newValue < props.min) {
        onChange(props.min)
        return;
      }
      if(newValue > props.max) {
        onChange(props.max)
        return;
      }
      sum < 0
        ? onChange(Math.ceil(newValue / props.step) * props.step)
        : onChange(Math.floor(newValue / props.step) * props.step)
  }

  return (
    <div className="ranger">
      <p className="ranger__title">{props.name}</p>
      <div className="ranger__container">
        <button className="ranger__button" type="button" onClick={() => editValueRange(- props.step)}>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 11H11H13H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13H11H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"/>
          </svg>
        </button>
        <div className="ranger__progress-bar">
          <div className="ranger__progress-value" style={{left: `${positionRange}px`}}>
            <div className="ranger__progress-value-item">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.79289 7.61872C3.40237 7.27701 3.40237 6.72299 3.79289 6.38128L8.79289 2.00628C9.18342 1.66457 9.81658 1.66457 10.2071 2.00628C10.5976 2.34799 10.5976 2.90201 10.2071 3.24372L5.91421 7L10.2071 10.7563C10.5976 11.098 10.5976 11.652 10.2071 11.9937C9.81658 12.3354 9.18342 12.3354 8.79289 11.9937L3.79289 7.61872Z"/>
              </svg>
            </div>
            <div className="ranger__progress-value-item">
              {firstValue} {props.currency}
            </div>
            <div className="ranger__progress-value-item">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.2071 7.61872C10.5976 7.27701 10.5976 6.72299 10.2071 6.38128L5.20711 2.00628C4.81658 1.66457 4.18342 1.66457 3.79289 2.00628C3.40237 2.34799 3.40237 2.90201 3.79289 3.24372L8.08579 7L3.79289 10.7563C3.40237 11.098 3.40237 11.652 3.79289 11.9937C4.18342 12.3354 4.81658 12.3354 5.20711 11.9937L10.2071 7.61872Z"/>
              </svg>
            </div>
          </div>
          <input
            ref={ref}
            className="ranger__progress"
            type="range"
            min={props.min}
            max={props.max}
            step={props.step}
            value={firstValue}
            onChange={(e) => setValueRange(Number(e.target.value))}
          />
        </div>
        <button className="ranger__button" type="button" onClick={() => editValueRange(+ props.step)}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0Z" />
          </svg>
        </button>
      </div>
    </div>
  )}
