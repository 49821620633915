import { getDomain } from "./getTld";

const data = {
	cz: {
		description: 'Finero je půjčka do 200 000 Kč s vysokou schvalovatelností a okamžitým vyřízením. Podívejte se na naši nabídku a získejte okamžitě peníze na cokoliv.',
		title: 'Finero - Půjčka na cokoliv'
	},
	kz: {
		description: 'Finero — Микрокредиты до 200 000 тенге с высоким процентом одобрения и немедленными выплатами. Ознакомьтесь с нашим предложением и получите деньги на вашу карту или банковский счет.',
		title: 'Finero - Микрокредит на что угодно!'
	},
	kz_kz: {
		description: 'Finero - 200 000 теңгеге дейінгі микрокредиттер жоғары мақұлдау мөлшерлемесі және дереу төленеді. Біздің ұсынысымызды қарап шығыңыз және картаңызға немесе банк шотыңызға ақша алыңыз.',
		title: 'Finero - кез келген нәрсеге микрокредит!'
	},
	mx: {
		description: 'Ofrecemos préstamos rápidos para cualquier propósito. Con procesamiento inmediato.',
		title: 'Finero - Préstamos rápidos'
	}
}

export const getRootMetadata = () => {
	const tld = getDomain(window.location.href);

	return data[tld];
}