import { getQueryParams } from "../../../utils/getQueryParams";
import { sendUserAction } from "./sendUserAction"

export const onClickBlockActionSend = (
	clickID: string,
	currentID: number,
	newID: number,
	name: string,
) => {
	if (currentID !== newID) {
		sendUserAction<boolean>(clickID, [{ name, value: true }]);
	}
}

export const sendToNextStepFormHandle = (clickID: string, step: number) => {
	switch (step) {
		case 0:
			sendUserAction<number | boolean>(clickID, [{ name: 'prelead_form_open', value: true }]);
			break;
		case 1:
			sendUserAction<number | boolean>(clickID, [{ name: 'prelead_form_submit', value: true }]);
			break;
		default:
			// sendUserAction<number | boolean>(clickID, [{ name: 'nextStep', value: value }]);
			break;
	}
}

export const sendInputFilledAction = (clickID: string, inputName: string, value: string | boolean) => {
	sendUserAction<string | boolean>(clickID, [{ name: inputName, value }]);
}

export const sendOffersOpened = (clickID: string) => {
	sendUserAction<boolean>(clickID, [{ name: 'offer_wall_open', value: true }]);	
}

export const sendQueryParams = (clickID: string | null) => {
	const url = window.location.href;

	const queryParams = getQueryParams(url);

	if (Object.keys(queryParams).length) {
		sendUserAction<Record<string, string | string[]>>(clickID, [{ name: 'queryParams', value: queryParams }]);
	}
}

export const sendSpentTime = (clickID: string, time: number) => {
	sendUserAction<number>(clickID, [{ name: 'time_spent', value: time }]);	
}

export const sendScrollPosition = (clickID: string, name: string, pos: number) => {
	sendUserAction<number>(clickID, [{ name, value: pos }]);	
}

export const sendNotValidInputField = (clickID: string, name: string, value: boolean) => {
	sendUserAction<boolean>(clickID, [{ name, value }]);	
}
