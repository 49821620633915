const data = {
	"items": [
		{
			"id": 1,
			"name": "first_name",
			"input_type": "text",
			"label": "Имя",
			"placeholder": "Имя",
			"validate": {
				"required": true,
				"minLength": 2,
				"pattern": {
					"reg": "/^[a-zA-Za-zA]+$/i",
					"message": "Имя может содержать только английские и русские буквы"
				}
			},
			"grid": 2
		},
		{
			"id": 2,
			"name": "last_name",
			"input_type": "text",
			"label": "Фамилия",
			"placeholder": "Фамилия",
			"validate": {
				"required": true,
				"minLength": 1,
				"pattern": {
					"reg": "/^[a-zA-Za-zA]+$/i",
					"message": "Фамилия может содержать только английские и русские буквы"
				}
			},
			"grid": 2
		},
		{
			"id": 3,
			"name": "email",
			"input_type": "text",
			"label": "Электронная почта",
			"description": "На эту электронную почту вы получите всю информацию по кредиту.",
			"validate": {
				"required": true,
				"pattern": {
					"reg": "^[a-zA-Z0-9]+@[a-zA-Z]+\\.[a-zA-Z]{2,4}$",
					"message": "Неправильный адрес электронной почты."
				}
			}
		},
		{
			"id": 4,
			"name": "phone",
			"input_type": "text",
			"label": "Мобильный телефон",
			"description": "На следующем этапе вы получите код на этот номер, который будет использоваться для проверки вашей заявки.",
			"validate": {
				"required": true,
				"pattern": {
					// "reg": "/^[7-7][0-9][0-9][\\s]?[0-9]{3}[\\s]?[0-9]{3}$/",
					"reg": "^[6-7][02379][0-9][\\s]?[0-9]{3}[\\s]?[0-9]{3}$/",
					"message": "Неправильный формат номера",
					"mask": "999 999 999"
				}
			}
		},
		{
			"id": 5,
			"name": "agreement",
			"input_type": "checkbox",
			"label": "Я ознакомлен с пользовательским соглашением и политикой обработки персональных данных.",
			"validate": {
				"required": true
			}
		},
	],
	"title": [
		"Регистрация",
		"",
		"",
		""
	],
	"buttons": {
		"next": "Продолжить",
		"prev": "Продолжить",
		"finish": "Продолжить"
	},
	"errors": {
		"required": "Это поле обязательно к заполнению.",
		"min": "Hodnota musí být větší",
		"max": "Vyberte hodnotu nižší",
		"minLength": "Должно быть не меньше 2-х символов.",
		"maxLength": "Слишком длинное слово.",
		"pattern": "Неправильный формат."
	},
	"range": {
		"title": "Деньги уже почти ваши",
		"description": "Кредит за 10 минут"
	},
	"stepsLength": 1,
}

export default data;
