import { Inputs } from "../../../utils/types";
import { RootState } from "../../rootReducer";

export const getRequestParams = (currentState: RootState, data: Inputs) => {
	// getting current state
	const mainData = currentState.mainData;
	const formData = currentState.formData.data;

	// creating URLSearchParams object to make query string
	const params = new URLSearchParams({
		requested_amount: mainData.money.toString(),
		period: mainData.period.toString(),
		aff: '710',
		agree: '1'
	});

	// if address has no difference adding contacts data
	if (!formData.different_address) {
		params.append('contact_zip', formData.zip_code);
		params.append('contact_city', formData.city);
		params.append('contact_street', formData.street);
		params.append('contact_house_number', formData.house_number);
		params.append('contact_home_status', formData.home_status);
	}

	// processing the form data
	Object.entries(data).forEach(([p, value]) => {
		const prop = convertFields(p);

		switch (prop) {
			case 'bank_acc_number':
				const currentValue = String(value);
				const hasDivider = currentValue.split('').includes('-');

				const [number, prefix] = hasDivider ? currentValue.split('-').reverse() : [currentValue];

				params.append('bank_account_prefix', (prefix || '000000').padStart(6, '0'));
				params.append('bank_account_number', (number || prefix).padStart(10, '0'));
				break;
			case 'marketing_checkbox':
				params.append(prop, Number(value).toString());
				break;
			case 'agreement':
				if (value) {
					params.append(prop, 'agreement');
				}
				break;
			case 'different_address':
				params.append('same_address', Number(value).toString());
				break;
			default:
				params.append(prop, value.toString());
				break;
		}

		// if (prop === 'bank_acc_number') {
		// 	const currentValue = String(value);
		// 	const hasDivider = currentValue.split('').includes('-');

		// 	const [number, prefix] = hasDivider ? currentValue.split('-').reverse() : [currentValue];

		// 	params.append('bank_account_prefix', (prefix || '000000').padStart(6, '0'));
		// 	params.append('bank_account_number', (number || prefix).padStart(10, '0'));
		// } else {
		// 	params.append(prop, value.toString());
		// }
	});

	// converting URLSearchParams object to string
	const url = params.toString();

	return url;
}

function generateTransactionID() {
	return Math.random().toString(36).substring(2, 9) + Date.now();
}

const getIP = async () => {
	const data = await fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=f54512750f884480b973f2351365eec4");
	const res = await data.json();

	return res.ip_address;
}

export const getKZRequestParams = async (currentState: RootState, data: Inputs) => {
	// getting current state
	const mainData = currentState.mainData;
	// const formData = currentState.formData.data;

	const ip = await getIP();

	const urlParams = new URLSearchParams(window.location.search);
	const aff = urlParams.get('affiliate') || '2059';
	const subaccount = urlParams.get('subaccount') || "kz964284";

	// creating URLSearchParams object to make query string
	const params = new URLSearchParams({
		uuid: "prelim",
		requested_amount: mainData.money.toString(),
		transaction_id: generateTransactionID(),
		aff,
		subaccount,
		product: 'uvpucnrkc9r72hik',
		client_ip: ip,
		period: '12',
	});

	// processing the form data
	Object.entries(data).forEach(([p, value]) => {
		const prop = convertFields(p);

		params.append(prop, value.toString());
	});

	// converting URLSearchParams object to string
	const url = params.toString();

	return url;
}

const convertFields = (prop: string) => {
	switch (prop) {
		case 'zip_code':
			return 'zip';
		case 'soc_sec_number':
			return 'birth_number';
		case 'phone':
			return 'cell_phone';
		case 'different_zip':
			return 'contact_zip';
		case 'different_city':
			return 'contact_city';
		case 'different_street':
			return 'contact_street';
		case 'different_house_number':
			return 'contact_house_number';
		case 'number_of_supp_children':
			return 'dependent_children';
		case 'op_number':
			return 'identity_card_number';
		case 'src_of_income':
			return 'income_type';
		case 'your_expenses':
			return 'monthly_expenses';
		case 'your_income':
			return 'monthly_income';
		case 'employer_addr':
			return 'employer_address';
		case 'name_of_employer':
			return 'employer';
		case 'how_long_had_income':
			return 'employed_time';
		case 'job_position':
			return 'job_title';
		default:
			return prop;
	}
}
