import { IGeneralConfig } from "../general.types";
import hero from './img/hero__img.webp';
import banner from './img/banner.webp';

const data: IGeneralConfig = {
	"common": {
		"primaryButtonText": "Получить деньги",
		"rangeData": {
			"summa": {
				"min": 1000,
				"max": 100000,
				"step": 1000,
				"currency": "₸",
				"firstValue": 10000,
				"name": "Сумма"
			},
			"period": {
				"min": 1,
				"max": 36,
				"step": 1,
				"currency": "Месяцев",
				"firstValue": 1,
				"name": "období"
			}
		},
		"steps": []
	},
	"header": {
		"logo": "",
		"links": [
			{
				"name": "Как получить займ",
				"link": "hero"
			},
			{
				"name": "Как это работает",
				"link": "how-works"
			},
			{
				"name": "Вопросы-ответы",
				"link": "faq"
			},
			{
				"name": "Контакты",
				"link": "contacts"
			}
		]
	},
	"main": {
		"title": "Преимущества Finero",
		"description": [
			"Оформление не более 1 минуты.",
			"Быстрое рассмотрение заявки.",
			"Без комиссий и скрытых платежей.",
			"Ваши персональные данные максимально защищены."
		],
		"img": {
			url: hero,
			alt: 'main image'
		},
		"rangeBlock": {
			"title": "Какая сумма вам необходима?",
			"description": "Получите кредит за 10 минут",
			"value": "Сумма",
			"period": "Období",
			"return": "Vrátit:",
			"date": "Na rande"
		},
		"answerBlock": [
			{
				"id": 1,
				"title": "Кто может это получить?",
				"description": "Частное лицо, 18 лет и старше, с мобильным телефоном и банковским счетом."
			},
			{
				"id": 2,
				"title": "Когда будут доступны деньги?",
				"description": "За 10 минут"
			},
			{
				"id": 3,
				"title": "Когда я смогу получить деньги?",
				"description": "Немедленно или на следующий день, в зависимости от банка."
			}
		]
	},
	"why": {
		"title": "Почему мы",
		"description": "Мы работаем на рынке уже много лет, мы выдали огромное количество кредитов, и вот наши преимущества:",
		"card_list": [
			{
				"id": 1,
				"description": "Кредит для всех (для всех видов доходов, с долгами)"
			},
			{
				"id": 2,
				"description": "С 2008 года на рынке было одобрено более 10 000 кредитов"
			},
			{
				"id": 3,
				"description": "У нас одни из самых быстрых выплат на европейском рынке"
			},
			{
				"id": 4,
				"description": "С помощью наших партнеров мы предлагаем наиболее выгодный вариант."
			}
		],
		"rating": [
			{
				"id": 1,
				"rating": 4.1,
				"title": "Наш рейтинг",
				"description": "Основано на 414 отзывах",
				"link": ""
			},
			{
				"id": 2,
				"rating": 4.1,
				"title": "",
				"description": "Кредит для всех (для всех видов доходов, с долгами)",
				"link": ""
			}
		]
	},
"footer": {
		"logo": "",
		"links": [],
		"text": [
			{
				"title": "",
				"description": [
					"Тәулігіне 0,01% (3,7%-дан ЖТСМ) және 172 500 000 теңгеге дейінгі сомамен."
				]
			},
			{
				"title": "",
				"description": [
					"info@finero.kz сайты тиесілі Heosphorus FZ-LLC"
				]
			},
			{
				"title": "",
				"description": [
					"Heosphorus FZ-LLC банк, микроқаржы ұйымы болып табылмайды және жүзеге асыру үшін республиканың уәкілетті мемлекеттік органынан рұқсат (лицензия) алуды талап ететін банк операциялары, микроқаржыландыру және/немесе кез келген басқа қаржылық қызмет санатына жататын қызметтерді көрсетпейді. Қазақстанның."
				]
			},
			{
				"title": "",
				"description": [
					"Heosphorus FZ-LLC қызметтерді жекелендіру және веб-сайттың ыңғайлылығын жақсарту үшін cookie файлдарын пайдаланады. Cookie файлдары веб-сайтқа алдыңғы кірулер туралы ақпаратты қамтитын шағын файлдар. Егер сіз cookie файлдарын пайдаланғыңыз келмесе, браузер параметрлерін өзгертіңіз."
				]
			},
			{
				"title": "",
				"description": [
					"www.finero.kz веб-сайты банктердің және/немесе микроқаржы ұйымдарының интернет-ресурсы болып табылмайды және тек үшінші тұлғалар көрсететін қызметтер туралы ақпарат беру және шағын несиелерді таңдау үшін құрылған."
				]
			}
		],
		"copyright": [
			"Copyright © 2024. Finero. Барлық құқықтар сақталған.",
			"Heosphorus FZ-LLC",
			"Орналасқан жері: SBAM0360 Compass Building, Al Shohada Road, Ras al Khaimah, UAE 0000004030573 info@finero.kz"
		]
	},
	"banner": {
		"title": "Давайте улучшим вашу кредитную историю",
		"description": [
			"Мы поможем вам улучшить или создать вашу кредитную историю с нуля!",
			"Вы можете взять долгосрочный кредит на более выгодных условиях!"
		],
		"img": banner,
	},
	"forWhat": {
		"descriptionBlock": {
			"title": "А для чего вы можете воспользоваться онлайн-займом?",
			"card_list": [
				{
					"id": 1,
					"title": "Погасить долг"
				},
				{
					"id": 2,
					"title": "Объедините несколько кредитов"
				},
				{
					"id": 3,
					"title": "Медицинские расходы"
				},
				{
					"id": 4,
					"title": "Отпуск или путешествие"
				}
			]
		},
		"priceBlock": {
			"title": "Сколько денег вам нужно",
			"buttons": [
				{
					"title": "100 тэнге",
					"value": 100
				},
				{
					"title": "200 тэнге",
					"value": 200
				},
				{
					"title": "300 тэнге",
					"value": 300
				},
				{
					"title": "500 тэнге",
					"value": 500
				},
				{
					"title": "1000 тэнге",
					"value": 1000
				},
				{
					"title": "1500 тэнге",
					"value": 1500
				},
				{
					"title": "2000 тэнге",
					"value": 2000
				},
				{
					"title": "3000 тэнге",
					"value": 3000
				}
			]
		}
	},
	"about": {
		"title": "Все, что вам нужно знать о нас",
		"description": [
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.", "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
		],
		"tableData": {
			"head": {
				"id": 1,
				"question": "Что вам нужно знать",
				"comment": "Зачем обращаться к нам?"
			},
			"body": [
				{
					"id": 2,
					"question": "Какую сумму могут занять новые клиенты?",
					"answer": "Až 5000 eur",
					"comment": "Никаких сборов, никогда!"
			  },
				{
					"id": 3,
					"question": "Каковы условия погашения кредита?",
					"answer": "от 3 до 36 месяцев",
					"comment": "Никакой бумажной волокиты"
			  },
				{
					"id": 4,
					"question": "Reprezentativní RPSN",
					"answer": "49,9 % (proměnná)",
					"comment": "Никакого обязывающего предложения"
			  },
				{
					"id": 5,
					"question": "Могу ли я подать заявление с плохой кредитной историей?",
					"answer": "Да",
					"comment": "Немедленное принятие решения"
			  },
				{
					"id": 6,
					"question": "Могу ли я платить в рассрочку",
					"answer": "Да",
					"comment": "Гибкое погашение кредита"
			  },
				{
					"id": 7,
					"question": "Проводят ли кредиторы проверку кредитоспособности?",
					"answer": "Да",
					"comment": "Мы выполняем только программный поиск"
			  }
			]
		}
	},
	"faq": {
		"title": "Вопросы и ответы",
		"data": [
			{
        "id": 1,
        "title": "Можно ли использовать мой смартфон для заполнения заявки на кредит?",
        "description": "Да, для оформления кредита вам достаточно иметь при себе только удостоверение личности, поэтому вы можете легко заполнить форму с помощью своего смартфона, компьютера или планшета."
      },
      {
        "id": 2,
        "title": "Ваши услуги бесплатны?",
        "description": "Все наши услуги для вас совершенно бесплатны. За использование нашей услуги вам НИКОГДА не будут начислены никакие сборы."
      },
      {
        "id": 3,
        "title": "У меня есть задолженность в реестре кредиторов, могу ли я получить кредит?",
        "description": "Запись в реестре не обязательно является препятствием для положительного рассмотрения вашей заявки. У вас все еще есть шанс получить кредит, даже если другие финансовые организации отказали вам. Однако и у нас проводится оценка кредитоспособности потребителя, чтобы выяснить, сможете ли вы погасить кредит."
      },
      {
        "id": 4,
        "title": "Будете ли вы взимать с меня дополнительные сборы?",
        "description": "Наша компания не требует никакой платы за использование наших услуг. Даже за кредит не взимается никаких предварительных сборов."
      },
      {
        "id": 5,
        "title": "Когда я получу деньги? *",
        "description": "Деньги будут переведены вам сразу после обработки и одобрения кредита. Денежные средства могут быть доступны уже через несколько минут, но не позднее следующего дня. Все зависит от того, в каком банке у вас открыт счет. В крупнейших чешских банках вы получите деньги сразу после одобрения."
      },
      {
        "id": 6,
        "title": "На что я могу использовать кредит?",
        "description": "Это не банковский кредит, поэтому вы можете использовать деньги на что угодно и не нужно указывать причину, для чего вам нужны средства."
      },
      {
        "id": 7,
        "title": "Могу ли я досрочно погасить кредит?",
        "description": "Да, вы всегда можете досрочно погасить ваш кредит. Вы заплатите только основную сумму и проценты."
      },
      {
        "id": 8,
        "title": "Мне нужен поручитель?",
        "description": "Нет, для получения кредита вам не нужен поручитель."
      }
		]
	},
	"congratulation": {
    "title": "Идет поиск предложений",
    "description": "В данный момент наша система ищет подходящее предложение именно для вас.",
    "clockTitle": "Пожалуйста, подождите"
},
	"analytics": {
		"googleAnalyticsScript": "<Script id=\"google-analytics\" strategy=\"beforeInteractive\">(function(b,c,h,p,q,e,r,w,d,k,t,a,l,m,f,n,u,g){function v(){for(var a=c.querySelectorAll('.dtpcnt'),b=0,d=a.length;b<d;b++)a[b][r]=a[b][r].replace(/(^|\\s+)dtpcnt($|\\s+)/g,'')}b[d]||(b[d]=function() {(b[d].q=b[d].q||[]).push(arguments)},m=c[p],c[p]=function() {m&&m.apply(this,arguments);if(b[d]&&!b[d].hasOwnProperty('params')&&/loaded|interactive|complete/.test(c.readyState))for(;a=c[q][k++];)/\\/?click($|(\\/[0-9]+)?$)/.test(a.pathname)&&(a[e]='javascrip'+b.postMessage.toString().slice(4,5)+':'+d+'.l=\"'+ a[e]+'\",void 0')},setTimeout(function(){(n=/[?&]cpid(=([^&#]*)|&|#|$)/.exec(b.location.href))&&n[2]&&(f=n[2],u=c.cookie.match(new RegExp('(^| )vl-'+f+'=([^;]+)')));a=c.createElement('script');l=c.scripts[0];a.defer=1;a.src=t+(-1===t.indexOf('?')?'?':'&')+'lpref='+h(c.referrer)+'&lpurl='+h(location[e])+'&lpt='+h(c.title)+'&t='+(new Date).getTime()+(u?'&uw=no':'');a[w]=function(){for(k=0;a=c[q][k++];)/dtpCallback\\.l/.test(a[e])&&(a[e]=a[e].match(/dtpCallback\\.l=\"([^\"]+)/)[1]);v()};l.parentNode.insertBefore(a, l);f&&(g=new Date,g.setTime(g.getTime()+864E5),c.cookie='vl-'+f+'=1; expires='+g.toGMTString()+'; path=/')},0),setTimeout(v,7E3))})(window,document,encodeURIComponent,'onreadystatechange','links','href','className','onerror','dtpCallback',0,'https://track.finero.mx/d/75a21d79-ef33-451e-a2d7-46d787bc51e8.js');</Script>",
		"googleTagManagerScript": "<Script id=\"google-tag-manager\" strategy=\"afterInteractive\">(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-MV3S9P8L');</Script>",
		"googleTagManagerNoScript": "<noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MV3S9P8L\" height=\"0\" width=\"0\" style=\"display: none; visibility: hidden;\"></iframe></noscript>"
	}
}

export default data;
